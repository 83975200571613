import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        // marginTop: 24,
        // backgroundColor:"red"
    },
    reportTitle:{
        color: 'black',
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
    },
    groupTitle:{
      color: 'black',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'left',
  }
  });


  const InvoiceTitle = ({item}) => (
    <View style={[styles.titleContainer,{
      marginTop:item.reports[0].group_title !=""? 8:15,

    }]}>
                      {/* //add group title seprate 1801348 9-jan-2023 by sir najam updation marginTop 24 tha line 8 me and add line 42  */}

      {console.log("InvoiceTitle me 23"+JSON.stringify(item.reports[0].group_title))}
         <Text style={styles.reportTitle}>{item.branch}</Text>
  <Text style={styles.reportTitle}>{"Printed on "+item.print_date+" at "+item.print_time}</Text>
  <Text style={{
       color: 'black',
       fontSize: 8,
       fontFamily: 'Helvetica',
       textAlign: 'left',
  }}>{item.Panel_Name.includes("Air MPL") ? "COVID-19 PCR FOR KSA": item.Panel_Name}</Text>
   {item.reports[0].group_title !=""?<Text style={styles.groupTitle}>{item.reports[0].group_title}</Text>:<></>}   
    </View>
  );
  
  export default InvoiceTitle