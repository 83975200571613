import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily: 'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,

    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items }) => {

    var rows = []
    var total_bill = 0
    var counter = 0
    items.map(item => {
        total_bill = total_bill + parseInt(item.Test_Price)
        counter = counter + 1
        return rows.push(<View style={{

            flexDirection: 'row',
            borderBottomColor: 'BLACK',
            borderBottomWidth: 1,
            alignItems: 'center',
            width: "100%",
            fontStyle: 'bold'
        }} key={item.id}>
            <Text style={{

                width: '5%',
                textAlign: 'left',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                paddingLeft: 8,
                height: '100%',


            }}>{counter}</Text>
            <Text style={{

                width: '8%',
                textAlign: 'left',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                paddingLeft: 8,
                height: '100%',


            }}>{item.invoice_no}</Text>

            <Text style={{

                width: '39%',
                textAlign: 'left',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                paddingLeft: 8,
                height: '100%',


            }}>{item.patient_name}</Text>
         
            <Text style={{

                width: '9.6%',
                textAlign: 'center',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                paddingLeft: 8,
                height: '100%'


            }}>{item.total_amount.toFixed(2)}</Text>
          

            <Text style={{

                width: '9.6%',
                textAlign: 'center',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                height: '100%',
                paddingLeft: 5,


            }}>{item.mpl_share.toFixed(2)}</Text>

            <Text style={{

                width: '9.6%',
                textAlign: 'center',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                height: '100%',
                paddingLeft: 5,


            }}>{item.franchise_share.toFixed(2)}</Text>
              <Text style={{

                width: '9.6%',
                textAlign: 'center',
                borderLeftColor: 'black',
                borderLeftWidth: 1,
                height: '100%',
                paddingLeft: 5,

                }}>{item.discount.toFixed(2)}</Text>
                <Text style={{

                width: '9.6%',
                textAlign: 'center',
                borderLeftColor: 'black',
                borderRightWidth: 1,
                borderLeftWidth: 1,
                height: '100%',
                paddingLeft: 5,


                }}>{item.net_amount.toFixed(2)}</Text>

        </View>)
    }
    )


    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow