import React, { useEffect, Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile, ConsoleView } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";


// import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import "./index.css"


import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';

import Slide from "@material-ui/core/Slide";

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';

import FeedingTable from './FeedingTable.js'

import Autocomplete from '@material-ui/lab/Autocomplete';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";


import MaterialTable from 'material-table'
import { BorderBottom, BorderLeft, BorderRight, Flag, PowerSettingsNew } from '@material-ui/icons';





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
function to24Hours(d) {
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    // fontSize: '0.5em', // Adjust this value to change the font size
    width: '100%',
  },
  inputRoot: {
    // fontSize: '0.5em', // Adjust this value to change the font size in the input
  },
  option: {
    fontSize: '0.7em', // Adjust this value to change the font size of options
    maxHeight: 1000,
  },
});

function msToTime(seconds) {

    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;

    return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    date.setDate(date.getDate() - 2);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    
    const [loading, setLoading] = React.useState(true)


    
  const [pdfScanFlag,setpdfScanFlag]=React.useState(false)
    
    const [branches, setBranches] = React.useState([])
    const [branch, setBranch] = React.useState("")
    const [selectBranch, setSelectBranch] = React.useState([])
    // 9-jan-2023
    // for departments
    const [departments,setDepartments] = React.useState([])
    const [selectedDepartments,setSelectedDepartments] = React.useState()



//for CallingUpTestsList_api 
const [feedResultInvoices,setfeedResultInvoices] = React.useState([
  
])
const [CallingUpTestsList,setCallingUpTestsList]=React.useState()

//for Test Title jo invoice select hui us se jo related test obj hn wo es me daly hn
const [testList,settestList] = React.useState([])
const [selectedTest, setselectedTest] = React.useState()
// jo test parameter next api se us k lye
const [testParametersFull,settestParametersFull] = React.useState([])
const [testParameter, settestParameter] = React.useState()



const [Patientid, setPatientid] = React.useState("")
// const [PatientMobile, setPatientMobile] = React.useState()
// used for search and Color change of selected invoice in Invoices  table  1-feb-2024
const [Invoiceid, setInvoiceid] = React.useState("")
// used for search and Color change of selected invoice in Invoices  table 
const [selectedTestAuxId, setselectedTestAuxId] = React.useState()
// for show name in material Table
const [selectedTestName, setselectedTestName] = React.useState("Tests Result Feeding ")
// for scan pdf
const [ScanPDF, setScanPDF] = React.useState('')
// for scan image 
const [ScanImage, setScanImage] = React.useState('')
//for patient name
const [name, setName] = React.useState("");




//for remarks
const [remarksInternal,setRemarksInternal]=React.useState("")
// for comments 
const [comments, setComments] = React.useState([])
// for open comments dialog box
const [openCommentDialodBox,setopenCommentDialodBox]=React.useState(false)
// for all comments coming from API
const [allComments,setallComments]=React.useState()
//for if_updated
const [ifUpdated, setIfUpdated] = React.useState(false)

// for if_auto_LIMS
const [ifAutoLims, setifAutoLims] = React.useState()
//for Rejection remarks
const [rejectionRemarks,setRejectionRemarks]=React.useState("")
//for age calculator of patient because in other way every time function call
const [agecalculate,setAgecalculate]=React.useState("")
// 18-sep-2024
const [openSecondPageBox,setopenSecondPageBox]=React.useState(false)
// 24-sep-2024 
const [prevResultCheckBox,setprevResultCheckBox]=React.useState(false)
// 22-nov-2024 
const [loadingNew,setloadingNew]=React.useState(false)
// 28-nov-2024
const [lookup, setLookup] = React.useState({});





// const calculateAge = (dobTimestamp) => {
//   // Assuming the timestamp is the number of days since epoch (Jan 1, 1970)
//   const dobDate = new Date(dobTimestamp * 24 * 60 * 60 * 1000); // Convert days to milliseconds
//   const today = new Date();

//   let age = today.getFullYear() - dobDate.getFullYear();
//   const monthDiff = today.getMonth() - dobDate.getMonth();
//   if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
//     age--;
//   }
  
//   return age;
// };
const calculateAge = (dobTimestamp) => {
  // Assuming the timestamp is the number of days since epoch (Jan 1, 1970)
  const dobDate = new Date(dobTimestamp * 24 * 60 * 60 * 1000); // Convert days to milliseconds
  const today = new Date();

  let years = today.getFullYear() - dobDate.getFullYear();
  let months = today.getMonth() - dobDate.getMonth();
  let days = today.getDate() - dobDate.getDate();

  // Adjust months and years if needed
  if (months < 0) {
    years--;
    months += 12;
  }

  // Adjust days and months if needed
  if (days < 0) {
    months--;
    const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonth.getDate();
  }
  console.log(years)
  console.log(months)
  console.log(days)

var total =`${years} y, ${months} m, ${days} d`
  return total;
};



   

    
    var svgString2Image = (svgString, width, height, format, callback) => {

        var svgStringFormat = window.atob(svgString)
        var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
        // create canvas in memory(not in DOM)
        var canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        var context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        var image = new Image();
        // later when image loads run this
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callbac
            callback(pngData)
        }; // end async
        image.src = svgData

    }

  

    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk"
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
            setBranches(response)

        })
        // for built in comments 
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCommentsWritingCallsUpWS_api").then(res => res.json()).then((result) => {
          // setBranches(response)
          // console.log("WebCommentsWritingCallsUpWS_api")
          // console.log(result)
          // console.log("WebDepartmentsCallingUpWS_api")
          const resultconver=JSON.parse(result.arr_comments_code)
                          // console.log(result)
                          var AllComments=[]
                          resultconver.map((item,index)=>{
                            var temp={
                              comment_code:item,
                              comment_text:JSON.parse(result.arr_comments_text)[index],
                                  select: <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                height: '50%',
                                backgroundColor:"#196c92"
                              }}
                              onClick={()=>{
                                // alert("hello")
                                var temp=JSON.parse(result.arr_comments_text)[index]+""
                                // console.log(comments)
                                // console.log("new")
                                // console.log(temp)

                                // setComments([...comments,temp])
                                setComments(comments => [...comments, temp]);
                                setopenCommentDialodBox(false)
      
                              }}
                              >Select</Button>
                            }
                            AllComments.push(temp)
                            // console.log(aikaor)
                          })
                          setallComments(AllComments)
                          // console.log(AllComments)

      })
        var patient_id = localStorage.getItem('patient_id')
        var role = localStorage.getItem('role')



        var branchID = localStorage.getItem('branch_id')
        var from = new Date();
        from.setDate(from.getDate() - 2);
        var to = new Date();

        var objj = {
          branch_id:parseInt(branchID),
          user_id:localStorage.getItem('user_id'),
          date_start:from.toISOString(),
          date_end:to.toISOString(),
          patient_id:"",
          invoice_id:"",
          array_dep_id:[]

        }
        // console.log("obj is "+JSON.stringify(objj))
fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
            method: "POST",
            body: JSON.stringify(objj)
        }).then((res) => res.json()).then((response) => {
            // console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))
            // 1-feb-2024 1
            // setCallingUpTestsList(response)

         var   obj={}
         var objnew=[]
            response.map((i,index)=>{
            // 1-feb-2024 1

                // const matchingPersons = objnew?.filter(data => data.invoice_id == i.invoice_id);
// if (matchingPersons.length > 0) { 
//   console.log(`is in the array. Matching objects:`, matchingPersons +"and "+JSON.stringify(i.invoice_id));
// } else {
//   console.log(` is not in the array.`);
//   objnew.push({invoice_id:i.invoice_id,
//     serial_no:i.serial_no,

//   })
// }
  // console.log(` is not in the array.`);
  objnew.push({
...i,
bookDateTime:new Date(i.booking_date).ddmmyyy()+",,"+msToTime(i.booking_time)+"hrs",
repotDateTime:new Date(i.reporting_date).ddmmyyy()+",,"+msToTime(i.reporting_time)+"hrs"

// select: (
//   <Button
//     variant="contained"
//     color="primary"
//     style={{
//       backgroundColor:"#196c91",color:"white"

//     }}
//     className={classes.button}
//     onClick={() => {
//       var objj = {
          
//         aux_id:i.aux_id,
//         invoice_id:i.invoice_id,
//         test_id:i.text_id

//       }
//       console.log("obj is "+JSON.stringify(objj))
// fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsParameters_api", {
//           method: "POST",
//           body: JSON.stringify(objj)
//       }).then((res) => res.json()).then((response) => {
//           console.log("Check RF_CallingUpTestParameter_api"+JSON.stringify(response))
//           settestParametersFull(response)
//           settestParameter(response.parameters_obj)
//           setInvoiceid(i.invoice_id)
//           setselectedTestAuxId(i.aux_id)
//           setselectedTestName(i.test_title)

         
//       })
     
//     }}
//   >
//     Select
//   </Button>
// ),
  })
            })
            // console.log("check obj value"+JSON.stringify(objnew))
                     // 1-feb-2024 1
         // setfeedResultInvoices(objnew)
            setCallingUpTestsList(objnew)


           
        })
   

            setLoading(false)


        // fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
        //     .then((result) => {
        //         setpanelList(result)
        //     })
        // for departsmnet
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDepartmentsCallingUpWS_api").then((res) => res.json())
            .then((result) => {
                // setpanelList(result)
                // console.log("WebDepartmentsCallingUpWS_api")
const resultconver=JSON.parse(result.arr_dep_id)
                // console.log(result)
                var AllDepartments=[]
                resultconver.map((item,index)=>{
                  var temp={
                    dep_id:item,
                    dep_name:JSON.parse(result.arr_dep_title)[index]
                  }
                  AllDepartments.push(temp)
                  // console.log(aikaor)
                })
                // console.log(AllDepartments)
                setDepartments(AllDepartments)
            })
        if (localStorage.getItem('org_branch_id') == '0') {
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
                setBranches(response)
                var obj = {}
                response.map((item) => {
                    obj[item.BranchID] = item.BranchName
                })
                setSelectBranch(obj)

                setBranch(localStorage.getItem('branch_id'))
            })
        }
setBranch("ALL")



    }, [])
    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
    
    
      
      const columnsNew = [
        // { title: 'Name', field: 'name', editable: 'never' },

        { title: 'Test Title', field: 'parameter_title', editable: 'never' },
        // 21-nov-2024
        // { title: 'Result', field: '',},
        { title: 'Result', field: 'category', 
          lookup: lookup
         },
        { title: 'Result', field: 'result_value', 
        //   cellStyle: {
        //   fontWeight: 'bold', // Make the text in the cells bold

        // }
        // cellStyle: (rowData) => {
        //   return{
        
        //     backgroundColor: rowData.result_value ==2 ? 'red' : 'pink', // Optional background color
        //   }
     
        //   // color: rowData?.result_value >= rowData?.range_min && rowData?.result_value <= rowData?.range_max ? 'black' : 'red', // Condition for text color
        //   // fontWeight: 'bold',
        // },
        render: (rowData) => {
          const actualValue = parseFloat(rowData.result_value);
          const minValue = parseFloat(rowData.range_min);
          const maxValue = parseFloat(rowData.range_max);
  
          if (isNaN(actualValue) || isNaN(minValue) || isNaN(maxValue)) {
            return (
              <div style={{ color: 'gray', backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                
              </div>
            );
          }
  
          const style = {
            color: actualValue >= minValue && actualValue <= maxValue ? 'black' : 'red',
            // backgroundColor: actualValue >= minValue && actualValue <= maxValue ? 'white' : 'pink',
            fontWeight: 'bold',
            // fontSize:12
          };
  
          return <div style={style}>{rowData.result_value}</div>;
        },
  
       },
        { title: 'Ref Range', field: 'ref_range', editable: 'never' },
        { title: 'Unit', field: 'test_units', editable: 'never' },
        { title: 'Previous 1', field: 'previous_result_1',editable: 'never'  },
        { title: 'Previous 1', field: 'previous_result_2',editable: 'never'  },


        

    ];
    const columns = [
      { title: 'S/N', field: 'serial_no', editable: 'never',  cellStyle: { width: '10000px' }, 
      headerStyle: { width: '10px' }  },
      { title: 'Invoice_Id', field: 'invoice_id',editable: 'never', },
      { title: 'MR_No', field: 'mr_no', editable: 'never' },
      { title: 'P_Name', field: 'patient_name', editable: 'never' },
      { title: 'P_Age', field: 'age', editable: 'never' },
      { title: 'P_Gender', field: 'gender', editable: 'never' },
      { title: 'Book_D/T', field: 'bookDateTime', editable: 'never' },
      { title: 'Test_Title', field: 'test_title', editable: 'never' },
      { title: 'Report_D/T', field: 'reporting_DateTime', editable: 'never' },
      { title: 'Department', field: 'department', editable: 'never' },
      { title: 'Machine', field: 'machine', editable: 'never' },
      { title: 'Ref_No', field: 'ref_no', editable: 'never' },
      { title: 'Ref_By', field: 'ref_by', editable: 'never' },
      { title: 'Panel', field: 'panel_name', editable: 'never' },
      { title: 'Branch', field: 'branch_name', editable: 'never' },
      // { title: 'Department', field: 'invoice_id', editable: 'never' },
      { title: 'Mobile_No', field: 'mobile', editable: 'never' },

  ];
    
    const fakecolumns = [
      { title: 'ID', field: 'id', editable: 'never' }, // 'never' means it's not editable
      { title: 'Name', field: 'name' },
      { title: 'Age', field: 'age' },
    ];
      // for pdf
      // const handleScanPDF = async (event) => {
      //   const file = event.target.files[0]
      //   console.log(file)
      //   var fileInput = document.getElementById('ScanPDF');
      //   var filePath = fileInput.value;
      //   var allowedExtensions = /(\.pdf)$/i;
    
      //   if (!allowedExtensions.exec(filePath)) {
      //     alert('Please upload file having extensions .pdf only.');
      //     fileInput.value = '';
      //     setScanPDF('')
      //     return false;
      //   } else {
      //     const base64 = await convertBase64(file)
      //     console.log("after convert into base64"+base64)
      //     // console.log("after convert into base64"+base64.split('base64,')[1])
    
      //     setScanPDF(base64)
      //   }
    
      // }
      const handleScanImage = async (event) => {
        // console.log("Invoice HandlerScanImage 417 :",event)
        const file = event.target.files[0]
        var fileInput = document.getElementById('ScanImage');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.exec(filePath)) {
          alert('Please upload file having extensions .jpeg/.jpg/.png only.');
          fileInput.value = '';
          setScanImage('')
          return false;
        } else {
          // console.log("Invoice HandlerScanImage 428 before convert into base :",file)
          const base64 = await convertBase64(file)
          // console.log("Invoice HandlerScanImage 430 after convert into base :",base64)
          setScanImage(base64)
        }
    
      }
      const handleScanPDF = async (event) => {
        const file = event.target.files[0]
        // console.log(file)
        var fileInput = document.getElementById('ScanPDF');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.pdf)$/i;
    
        if (!allowedExtensions.exec(filePath)) {
          alert('Please upload file having extensions .pdf only.');
          fileInput.value = '';
          setScanPDF('')
          return false;
        } else {
          const base64 = await convertBase64(file)
          // console.log("after convert into base64"+base64)
          // console.log("after convert into base64"+base64.split('base64,')[1])
    
          setScanPDF(base64)
        }
    
      }
      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }
    return (
        <>
            <Paper className={classes.root} style={{}}>
                {/* <GridContainer style={{ paddingBottom: '2em',paddingLeft: '2em',paddingRight: '2em', }}> */}
                <GridContainer  style={{ paddingLeft: '2em',paddingRight: '2em',marginTop:"" }}>
                    
                
                    <GridItem xs={6} sm={6} md={4} lg={4} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",}}  >
                        <FormControl variant="filled" className={classes.formControl} style={{ width: "8em", marginLeft:0 }} >
                            <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={branch}
                                onChange={(e) => {
                                    var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                                    if (result) {
                                        setBranch(e.target.value)
                                        localStorage.setItem('branch_id', e.target.value)
                                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                                        window.location.reload()
                                    }

                                }}
                            >
                                <MenuItem value={"0"} disabled>Select</MenuItem>
                                {
                                    branches?.map((item) => {
                                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker style={{ marginLeft:20,width:"8.5em"}}
                        variant="inline"
                        inputVariant="outlined"
                        label="From"
                        format="dd/MM/yyyy"
                        value={from}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setFrom(date)}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                    style={{ marginLeft: 20,width:"8.5em"}}
                        variant="inline"
                        inputVariant="outlined"
                        label="To"
                        format="dd/MM/yyyy"
                        value={to}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setTo(date)}
                    />
                </MuiPickersUtilsProvider>
                    </GridItem>
                    
            
            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        label="From"
                        format="dd/MM/yyyy"
                        value={from}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setFrom(date)}
                    />
                </MuiPickersUtilsProvider>
            </GridItem> */}
            {/* {
                width < 600
                    ?
                    <GridItem xs={6} sm={6} md={0} lg={0}>

                    </GridItem> : ""
            } */}

            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        label="To"
                        format="dd/MM/yyyy"
                        value={to}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setTo(date)}
                    />
                </MuiPickersUtilsProvider>

            </GridItem> */}
            {/* {
                width < 600
                    ?
                    <GridItem xs={6} sm={6} md={0} lg={0}>

                    </GridItem> : ""
            }
             */}
    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>
        

    
    </GridItem> */}
    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>

    </GridItem> */}
    <GridItem xs={6} sm={6} md={6} lg={5} style={{display:"flex",marginTop:"0.5em",}}>
    <Autocomplete
          id="Ref_By_Doctor"

          options={departments}
          classes={{
            // option: classes.option
            option: classes.option,
        root: classes.root,
        inputRoot: classes.inputRoot,
          }}
          autoHighlight
          getOptionLabel={(option) => option.dep_name + ""}
          style={{
            width: "8em",
            
            marginRight: '1em'
          }}
          // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
          onChange={(event, newValue) => {
        //  console.log(newValue)
         if(newValue){
         setSelectedDepartments(newValue.dep_id)
         }else{
          setSelectedDepartments(null)
         }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departments"
              variant="outlined"
              style={{
                // width: "30em"
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
    <TextField
      id="outlined-required"
      label="Patient ID"
      value={Patientid}
      onChange={(e) => {
        setPatientid(e.target.value);
      }}
      style={{
        width: "8em",
        
        marginRight: '1em'
      }}
      variant="outlined"
    />

    <TextField
      id="outlined-required"
      label="Invoice ID"
      value={Invoiceid}
      onChange={(e) => {
        setInvoiceid(e.target.value);
      }}
      style={{
        width: "8em",
        
        marginRight: '1em'
      }}
      variant="outlined"
    />
                          <Button variant="contained"  style={{backgroundColor:"#196c91",color:"white", width: "8em",maxHeight:"4em",
        
        marginRight: '1em'}} disabled={loading}
onClick={() => {
// alert("DATE from"+from+"DATE to "+to+"invoice id"+Invoiceid+"patient iD"+Patientid)
 //  SeveRecordHandler()
 if(localStorage.getItem('branch_name')){
var branchID = localStorage.getItem('branch_id')

 var obj = {
  branch_id:parseInt(branchID),
  user_id:localStorage.getItem('user_id'),
  date_start:from.toISOString(),
  date_end:to.toISOString(),
  patient_id:Patientid,
  invoice_id:Invoiceid,
  array_dep_id:selectedDepartments?[selectedDepartments]:[]

}
//  console.log("checj obj "+JSON.stringify(obj))
 fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
    method: "POST",
    body: JSON.stringify(obj)
}).then((res) => res.json()).then((response) => {
    // console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))
    // 1-feb-2024 1
    // setCallingUpTestsList(response)

 var   obj={}
 var objnew=[]
    response.map((i,index)=>{
    // 1-feb-2024 1

        // const matchingPersons = objnew?.filter(data => data.invoice_id == i.invoice_id);
// if (matchingPersons.length > 0) { 
//   console.log(`is in the array. Matching objects:`, matchingPersons +"and "+JSON.stringify(i.invoice_id));
// } else {
//   console.log(` is not in the array.`);
//   objnew.push({invoice_id:i.invoice_id,
//     serial_no:i.serial_no,

//   })
// }
// console.log(` is not in the array.`);
objnew.push({
  
...i,
bookDateTime:new Date(i.booking_date).ddmmyyy()+",,"+msToTime(i.booking_time)+"hrs",
repotDateTime:new Date(i.reporting_date).ddmmyyy()+",,"+msToTime(i.reporting_time)+"hrs"

})
    })

    setCallingUpTestsList(objnew)           
})
 }
 else{
  alert("Please Select Branch !")
 }
}}                  >
                    Search<SearchIcon />
                </Button>
            
         
    </GridItem>
           
            
            <GridItem xs={12} sm={12} md={2} lg={3} style={{ margin: 'auto',display:"flex",justifyContent:"start",alignItems:"start", }} >

            <div  style={{fontSize:12,justifyContent:"start",alignItems:"start",display:"flex",flexDirection:"column"}}>
            <p style={{marginTop:-10,fontWeight:"bold",color:"#196c92"}}><span style={{fontWeight:"bold",color:"black"}}>Ref_By_Doctor :</span> {testParametersFull.doctor_name}</p>
                         {/* <p></p> */}
                          
                {/* <p>helloaaaaa a aaa1</p> */}
                {/*  var ds = new Date(data.Booking_DATE).ddmmyyy();
            var time = msToTime(data.Booking_TiME); */}
                <p style={{marginTop:-10,fontWeight:"bold",color:"#196c92"}}><span style={{fontWeight:"bold",color:"black"}}>Reg Date & Time :</span>{testParametersFull!=""? new Date(testParametersFull.invoice_date).ddmmyyy()+","+msToTime(testParametersFull.invoice_time):""}</p>

                </div>
                {/* <div  style={{fontSize:15,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",marginLeft:2}}>
                <p>Patient_name :{testParametersFull.patient_name}</p>
                <p style={{marginTop:-10}}>Ref_By_Doctor:{testParametersFull.panel_name}</p>

                </div> */}
                < PowerSettingsNew onClick={()=>{
                  //  deleteAllCookies()
                  var result = window.confirm('Are you Sure ?. Your want to Logout ?')
                  if (result) {
                    localStorage.clear()
                    // window.location.href="https://invoicing.mpl-labs.pk/login"
                      window.location.reload()
                  }
                   
                  // alert("hy")
                }} style={{cursor:"pointer",position:"absolute",right:10,top:-10}}></PowerSettingsNew>

            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} style={{ margin: 'auto',display:"flex",justifyContent:"start",alignItems:"start", }} >

<div  style={{fontSize:12,justifyContent:"start",alignItems:"start",display:"flex",flexDirection:"row"}}>
<p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black"}}>User :</span><span className='blink'>{localStorage.getItem('name')}</span></p>

{testParameter?<>
   <p  style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Mr_No :</span>{testParametersFull.mr_number}</p>
   <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Patient_name :</span>{testParametersFull.patient_name}</p>

           
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Gender :</span>{testParametersFull.gender}</p>
    {/* // 1-jun-20 */}
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Age :</span>{agecalculate}</p>
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Gender :</span>{testParametersFull.gender}</p>
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:10}}>Mobile :</span>{testParametersFull.mobile_no}</p>

    </>:<></>}
    </div>
    {/* <div  style={{fontSize:15,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",marginLeft:2}}>
    <p>Patient_name :{testParametersFull.patient_name}</p>
    <p style={{marginTop:-10}}>Ref_By_Doctor:{testParametersFull.panel_name}</p>

    </div> */}
   

</GridItem>
            </GridContainer>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        {/* <GridContainer> */}
                        <GridContainer style={{ 
                          
                          // paddingLeft: '1em',paddingRight: '1em',marginTop:"", 
                          }}>
                            {/* <GridItem md={3}>
                                {
                                    Object.keys(testRecord).length>1 ?
<FeedingTable testRecord={testRecord} />
: null
                                }
                                
                            </GridItem> */}
                             {/* <GridItem md={4}>
                             <Autocomplete
                          
                          disablePortal
                          id="combo-box-demo"
                          options={["110022","12122","12112"]}
                          sx={{ width: 300 }}
                          style={{marginLeft:10}}
                          renderInput={(params) => <TextField {...params} label="Invoice Selection" />}
                          value={""}
                          onChange={(event, newInputValue) => {


                          }}
                          
                          />
                          </GridItem> */}
                          {/* <GridItem md={8}></GridItem> */}
                          {/* For Histo Departments  aREA */}

                          {/* <GridItem md={4} lg={4} style={{ paddingLeft: '2em',paddingTop: '2em',paddingBottom: '2em' }}> */}
                          <GridItem md={12} lg={12} style={{
                            // height: "400px", overflow: "auto",
                            // borderBottom:"1px solid #E4E4E4", 
                            // border:"10px solid #E4E4E4" ,
                            // overflow: "auto", 
                            // height: "1000px",
                             }}>
       <MaterialTable
      //  style={{marginLeft:-12}}
      style={{border:"2px solid #ccc"}}
      title={<p style={{ fontSize:16 }}>Invoices</p>}
      columns={columns}
      data={CallingUpTestsList}
      options={{
        // tableLayout: "fixed", // Fixes table layout
        // paging: false,
        // headerStyle: {
        //   backgroundColor:"#196c91",
        //   color: "#FFF",
        //   // fontSize: "17px",
        //   // textAlign: "center",
        //   fontWeight: "bold",
        //   // minHeight:"50em"
        // },
        headerStyle: {
          backgroundColor:"#196c91",
          color: "#FFF",
          fontSize: 12,
          position: "sticky", // Make the header sticky
      top: 0, // Stick the header to the top
          // textAlign: "center",
          // fontWeight: "bold",
          // minHeight:"50em"
        },
        maxBodyHeight: "1000px",
        pageSize: 100,
        fixedHeader: true,
       
      
      //   rowStyle: rowData => {
      //     // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
      //     //   return {};
      //     // }
      //     // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
      //   //   return {backgroundColor:rowData.if_late &&rowData.invoice_id!=Invoiceid &&rowData.aux_id!=selectedTestAuxId? "#ff1212":rowData.invoice_id==Invoiceid && rowData.aux_id==selectedTestAuxId?"#cbcbcb":"",
      //   //   color:rowData.if_late &&rowData.invoice_id!=Invoiceid? "white":"",
      //   // };
      //   return {
      //     backgroundColor:rowData.if_late &&rowData.invoice_id!=Invoiceid &&rowData.aux_id!=selectedTestAuxId? "#ff1212":rowData.invoice_id==Invoiceid && rowData.aux_id==selectedTestAuxId?"#cbcbcb":"",
      //   color:rowData.if_late &&rowData.invoice_id!=Invoiceid? "white":"",
      //   fontSize: 10,
        
      // };
      //   }
      // rowStyle: (rowData, index) => {
      //   // Determine Invoice_ID for current, next, and previous rows
      //   const currentInvoiceID = rowData.Invoice_ID;
      //   const nextInvoiceID = index < CallingUpTestsList.length - 1 ? CallingUpTestsList[index + 1].Invoice_ID : null;
      //   const previousInvoiceID = index > 0 ? CallingUpTestsList[index - 1].Invoice_ID : null;
  
      //   // Apply background color based on Invoice_ID matching
      //   const backgroundColor =
      //     currentInvoiceID == nextInvoiceID || currentInvoiceID == previousInvoiceID
      //       ? '#d1e1e9' // Set color if the current ID matches next or previous
      //       : index % 2 === 0
      //       ? '' // Default color for even rows
      //       : '#EEEEEE'; // Alternate color for odd rows
  
      //   return {
      //     backgroundColor,
      //     fontSize: 10,
      //   };
      // }
      rowStyle: (rowData, index) => {
        // console.log(rowData)
        const currentInvoiceID = rowData?.invoice_id;
        const nextInvoiceID = index < CallingUpTestsList.length - 1 ? CallingUpTestsList[index + 1]?.invoice_id: null;
        const previousInvoiceID = index > 0 ? CallingUpTestsList[index - 1]?.invoice_id: null;
  
        // Debugging
        // console.log(`Row: ${index}, Current: ${currentInvoiceID}, Next: ${nextInvoiceID}, Previous: ${previousInvoiceID}`);
  
        // Apply background color based on conditions
        const backgroundColor =
          currentInvoiceID === nextInvoiceID || currentInvoiceID === previousInvoiceID
            ? '#d1e1e9' // Set color if the current ID matches next or previous
            : index % 2 === 0
            ? '#FFFFFF' // Default color for even rows
            : '#EEEEEE'; // Alternate color for odd rows
  
        return {
          backgroundColor,
          fontSize: 12,
        };
      }
      }}
      onRowClick={(event, rowData, togglePanel) => {
        // setOpenRow(openRow === rowData.id ? null : rowData.id);
        if(localStorage.getItem('branch_name')){
         
          setopenSecondPageBox(true)
          // setloadingNew(true)
        // togglePanel();
        // console.log("data is "+JSON.stringify(rowData))
        
      var j=0

        var object = {   
          // aux_id:594980,
          // invoice_id:"0502208",
          // test_id:"UR-001-T-0409" 
          aux_id:rowData.aux_id,
          invoice_id:rowData.invoice_id,
          test_id:rowData.text_id
        }
        // console.log("object is "+JSON.stringify(object))
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsParameters_api", {
            method: "POST",
            body: JSON.stringify(object)
        }).then((res) => res.json()).then((response) => {
            console.log("Check RF_CallingUpTestParameter_api"+JSON.stringify(response))
            // yhan se comment kiya 
  //           const parentId = j;
  //           var obj={
  //             id:j,
  //             name:rowData.test_title
  //           }
  //           console.log("obj is "+JSON.stringify(obj))
            
  
  // // Add parentId property to each object in the array
  // const arrayWithParentId = response.parameters_obj.map((obj,index)=> (
     
  //   {
  //     ...obj,
  //     parentId: parentId,
  //     id:100
     
  // }


  // ));
  // arrayWithParentId.push(obj)
  // console.log("Check 2nd API "+JSON.stringify(arrayWithParentId))
  // settestParameter(arrayWithParentId)
  
  //           // var parentId=i
  //           // var parId=response.parameters_obj+x
            
  //           // var holdParameterObject={...response.parameters_obj,parentId}
  //           // grandObj.push(holdParameterObject)
  //           // console.log("Check RF_CallingUpTestParameter_api 2nd"+JSON.stringify(holdParameterObject))
  
  //           // grandObj.push({
  //           //   id:i,
  //           //   test:response.parameters_obj
  //           // })
  //           // settestParametersFull(response)
  //           // settestParameter(response.parameters_obj)
  //           setInvoiceid(rowData.invoice_id)
  //           setselectedTestAuxId(rowData.aux_id)
  //           setselectedTestName(rowData.test_title) 
  // yhan tak
                        settestParametersFull(response)
            settestParameter(response.parameters_obj)
            // setInvoiceid(rowData.invoice_id)
            setselectedTestAuxId(rowData.aux_id)
            setselectedTestName(rowData.test_title)
            setIfUpdated(false)
            setifAutoLims(response.if_feeding_lims) 
            setName(response.patient_name)
            setComments(response.remarks_tests)
            // 1-jun-20
            setAgecalculate(calculateAge(response.age_days))
            // setloadingNew(false)

              // Parse the array from the API response
      const posNegArray = JSON.parse(response.pos_neg_arr);

      // Create a lookup object dynamically
      const dynamicLookup = posNegArray.reduce((acc, item, index) => {
        acc[item] = item; // Use index + 1 as the key
        return acc;
      }, {});

      setLookup(dynamicLookup);
        })
      }
      else{
        alert("Please Select Branch !")
        // setloadingNew(false)

      }
      }}
      // cellEditable={{
        
      //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
      //     return new Promise((resolve, reject) => {
      //       console.log('newValue: ' + newValue);
      //       setTimeout(resolve, 1000);
      //     });
      //   }
      // }}
    />
</GridItem>

                          {/* <GridItem md={12} lg={8} style={{ paddingRight:"2em",paddingTop: '2em',paddingBottom: '2em' }}> */}
                          {/* <GridItem md={8} lg={8} style={{ paddingRight:"2em",height: "400px", overflow: "auto" ,borderBottom:"1px solid #E4E4E4", borderTop:"1px solid #E4E4E4",  }}> */}
                          {/* <MaterialTable
      title="Cell Editable Preview"
      columns={columnsNew}
      data={dataNew}
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            console.log('newValue: ' + newValue);
            setTimeout(resolve, 1000);
          });
        }
      }}
    /> */}
    {/* 10-sep-2024 it will show on 2nd page of feed  */}
    {/* <MaterialTable
   title={  <p style={{ fontSize: '18px' }}>{selectedTestName}</p>}
   
      columns={columnsNew}
      data={testParameter}
      // options={{actionsColumnIndex: -1  }}
      options={{
        selection: false,
        headerStyle: {
       
          fontSize: 10,
          // textAlign: "center",
          backgroundColor:"#196c91",
          color: "#FFF",
  
         
        },
        minHeight:"60em",
       
           pageSize: 20,
        actionsColumnIndex: -1,
        rowStyle: rowData => {
          
          return {
          fontSize: 10,
          
        };
        } ,

      }}
      
      // options={{
      //   // paging: false,
      //   pageSize: 10,
      //   actionsColumnIndex: -1,
      //   // headerStyle: {
      //   //   backgroundColor:"#196c91",

      //   //   color: "#FFF",
      //   //   // fontSize: "17px",
      //   //   // textAlign: "center",
      //   //   fontWeight: "bold",
      //   //   // minHeight:"50em"
      //   // },
      //   headerStyle: {
      //     backgroundColor:"#196c91",
      //     color: "#FFF",
      //     fontSize: 10,
      //     // textAlign: "center",
      //     fontWeight: "bold",
      //     // minHeight:"50em"
      //   },
      //   // grouping: true,
      //   rowStyle: rowData => {
      
      //     // return {backgroundColor:rowData.rfd>0? "#ffffe0":"", };
      //     return {
      //       fontSize: 10,
            
      //     };
      //   }
      // }}
      // yhan se 
      // parentChildData={(row, rows) => 
      //   rows.find(a => a.id == row.parentId)
      
      // }
      // yhan tak 
      

      editable={{
        isEditable: () => false, // Disable default row editing
        onBulkUpdate: (changes) =>
          new Promise((resolve, reject) => {
            const rows=Object.values(changes)
            console.log("check edit ",rows)

            const updatedRows = [...testParameter];
            let index;
            rows.map (emp=>{
              console.log(emp.oldData.tableData.id)
              index=emp.oldData.tableData.id
              updatedRows[index]=emp.newData
            })
            settestParameter(updatedRows)
            console.log(updatedRows)
          
            resolve();
          }),
      }}
      // components={{
      //   EditField: props => (
      //     <input
      //       {...props}
      //       style={{ ...props.style, fontSize: 5 }}
      //       onChange={e => props.onChange(e.target.value)}
      //     />
      //   ),
      // }}
      
    />
     */}
   
                         {/*es pe testing ki thi parents child wali  */}

                                {/* <MaterialTable
                                style={{borderStyle:"solid",borderColor:"#D3D3D3"}}
                                icons={tableIcons}
                                title="Test Result Feeding "
                                data={data}
                                columns={[
                                    { title: 'Title', field: 'title' , editable:'never'},
                                    { title: 'Name', field: 'name' , editable:'never' ,
                                    cellStyle: {
                                        fontWeight:"bold"
                                    
                                    }
                                },
                                    { title: 'Value', field: 'value',
                                     editable: 'always' ,
                                    // editable: rowData => rowData.editable,
                                    cellStyle: {
                                        color: "#039be5",
                                       fontWeight:'bold'
                                      },
                                      headerStyle: {
                                        backgroundColor: '#039be5',
                                      }


                                },
                                    { title: 'Ref Range', field: 'ref_range' , editable:'never' ,
                                
                                    cellStyle: {
                                        color: 'red'
                                      }
                                    
                                },
                                    { title: 'Unit', field: 'unit' , editable:'never'}
                                    
                                ]}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                enableEditing={true}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                      new Promise((resolve, reject) => {
                                        setTimeout(async () => {
                                            const dataUpdate = [...data];
                                            console.log("dataUpdate"+JSON.stringify( dataUpdate))
                                            const index = oldData.tableData.id;
                                            console.log("index"+index)
                                            console.log("NewData"+JSON.stringify(newData))
                                            
      
                                              // dataUpdate[index].receivedValue = newData.receivedValue;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);
                                        
                    
                                            resolve();
                                          }, 1000)
                                      console.log("check full data"+JSON.stringify(data))

                                      }),
                                  }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                      return new Promise((resolve, reject) => {
                                        console.log('newValue: ' + newValue);
                                        setTimeout(resolve, 1000);
                                      });
                                    }
                                }}
                                /> */}
                           
                                {/* </GridItem> */}
                            

                        </GridContainer>
                        {/* 10-sep-2024 all of these going to b second page  */}
                        {/* <GridContainer>
                        <GridItem md={4} lg={2}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:25
     }}>
       <b>Remarks (Internal Use)</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1,
           width:"115%"
         }}
         value={remarksInternal}
         onChange={(e) => {
           setRemarksInternal(e.target.value)
         }}
         placeholder="Remarks for Internal use only "
       /></span>
                     </GridItem>
                     
                     <GridItem md={4} lg={3}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:25
     }}>
       <b>Comments</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1
         }}
         value={comments}
         onChange={(e) => {
           setComments(e.target.value)
         }}
         placeholder="Comments to Print Ends of Reports"
       />
       </span>
                     </GridItem>
                     <GridItem md={2} lg={1}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
            //  SeveRecordHandler()
            setopenCommentDialodBox(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3.8em",
             marginLeft: "2px",
             marginTop:25,
            //  marginBottom:10
            backgroundColor:"#196c91"
           }}>
                Comments
           </Button>
                     </GridItem>
                     <GridItem md={6} lg={6} style={{ paddingTop: '1.5em',display:"flex",flexDirection:"row" }}>
                      <div style={{width:"20%"}}>
                          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}
            >
 <FormControlLabel
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label="Scan PDF"
            />
            
          
            </Button>
            </div>
            {
            !pdfScanFlag
            ?
            <div style={{width:"30%"}}>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}
            

          >
            <b>Attach PDF</b>
            <input type="file" variant="contained"
              // color="primary"
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF" name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
            ></input>
          </Button>
          </div>
          :<></>
}
{
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button>
          </>
          :
          null
         }
          <div style={{width:"30%"}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{width:"90%",paddingTop:"8%",paddingBottom:"8%",marginLeft:"70%",backgroundColor:"#196c91"}}
          >
            <b> Image</b>
            <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleScanImage(e) }}
              id="ScanImage" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            ></input>
          </Button>
          </div>
                                </GridItem>
                                
                     </GridContainer>
                     <GridContainer>
                     
                     <GridItem md={4} lg={4}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:25
     }}>
       <b>Rejection Remarks </b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1
         }}
         value={rejectionRemarks}
         onChange={(e) => {
           setRejectionRemarks(e.target.value)
         }}
         placeholder="Remarks for Internal use only "
       /></span>
                     </GridItem>
                     <GridItem md={2} lg={2}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       alert("hello"+rejectionRemarks)
            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
            //  marginLeft: "2px",
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
               Reject to phlebotomy
           </Button>
           
                     </GridItem>
                     <GridItem md={2} lg={2}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       alert("hello")
            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
               Result From LMS
           </Button>
                     </GridItem>
       
                     <GridItem md={3} lg={3}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
      // for branch selection 
      if(localStorage.getItem('branch_name')){

        

var arrparameterid = []
var arrgrouptitle = []
var arrparametertitle = []
var arrapplygender = []
var arrapplyage = []
var arrresultvalue = []
var arrresultstatus = []
var arrtestunit = []
var arrtestrefrange = []
var arrtestparagraph = []
var arrprevious1 = []
var arrprevious2 = []
      var obj ={}
   var data= testParameter.map((item,index)=>{
console.log("value in testParameter is "+JSON.stringify(item))
arrparameterid.push(item.aux_id)
arrgrouptitle.push("")

arrparametertitle.push(item.parameter_title)
arrapplygender.push(item.apply_gender)
arrapplyage.push(item.apply_age)
arrresultvalue.push(item.result_value)
arrresultstatus.push(item.result_status)
arrtestunit.push(item.test_units)
arrtestrefrange.push(item.ref_range)
arrtestparagraph.push(item.test_paragraph)
arrprevious1.push(item.previous_result_1)
arrprevious2.push(item.previous_result_2)



delete item.tableData
// return item
      })
      console.log({
       
        
      })
      console.log(arrparameterid)

      // console.log("value delete change"+JSON.stringify(testParameter))
      testParametersFull.parameters_obj=testParameter
      var obj = {
        aux_id:selectedTestAuxId,
        remarks_tests:remarksInternal,
        comments_tests:comments.toString(),
        if_updated:ifUpdated,
        if_auto_LIMS:ifAutoLims,
        user_id:localStorage.getItem('user_id'),
        user_name:localStorage.getItem('name'),
        PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
        PDF_Name: ScanPDF != "" ? name+".pdf" : "",
        Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
        Image_Name: ScanImage != "" ? name + ".png" : "",
        arr_paramter_id:arrparameterid,
        arr_group_title:arrgrouptitle,
        arr_paramter_title:arrparametertitle,
        arr_apply_gender:arrapplygender,
        arr_apply_age:arrapplyage,
        arr_result_value:arrresultvalue,
        arr_result_status:arrresultstatus,
        arr_test_unit:arrtestunit,
        arr_test_ref_range:arrtestrefrange,
        arr_test_paragraph:arrtestparagraph,
        arr_previous_1:arrprevious1,
        arr_previous_2:arrprevious2
      
      }
       console.log("checj obj "+JSON.stringify(obj))
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
          method: "POST",
          body: JSON.stringify(obj)
      }).then((res) => res.json()).then((response) => {
        console.log(response)
        alert("Successfully done "+JSON.stringify(response))
      })





      // settestParametersFull(...testParametersFull)

      // console.log("parameter that will change"+JSON.stringify(testParameter))
      // console.log("parameter Full new"+JSON.stringify(testParametersFull))

            //  SeveRecordHandler()

    }else{
      alert("Please Select Branch !")
    }
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
             padding: "2em",
             paddingLeft:"3em",
             paddingRight:"3em",
            // paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             marginLeft: "3.5em",
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Save Records
           </Button>
                     </GridItem>
                    
                                
                     </GridContainer> */}
                    </>
                }
                


            </Paper>
            {/* 2nd page open for test feeding */}
            <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        // sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '' } }}
        maxWidth={false}
        fullWidth={true}
        open={openSecondPageBox}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
      setopenSecondPageBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Result Feeding</b></h4>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          // className={classes.modalBody}
        >
               <GridContainer  style={{ paddingLeft: '2em',paddingRight: '2em',marginTop:"" }}>
             
       <GridItem md={8} lg={8} style={{borderBottom:"1px solid #E4E4E4", borderTop:"1px solid #E4E4E4",  }}>
                          {/* <MaterialTable
      title="Cell Editable Preview"
      columns={columnsNew}
      data={dataNew}
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            console.log('newValue: ' + newValue);
            setTimeout(resolve, 1000);
          });
        }
      }}
    /> */}
    {/* 10-sep-2024 it will show on 2nd page of feed  */}
    {/* <ReactLoading style={{}}  type={'balls'} color={'black'} height={50} width={50} /> */}

    {loadingNew?<>
      <ReactLoading style={{}}  type={'spinningBubbles'} color={'black'} height={15} width={15} />
      </>:
      
    <MaterialTable
   title={  <p style={{ fontSize: '18px' }}>{selectedTestName}</p>}
   
      columns={columnsNew}
      data={testParameter}
      // options={{actionsColumnIndex: -1  }}
      options={{
        selection: false,
        headerStyle: {
          backgroundColor:"#196c91",
          color: "#FFF",
          fontSize: 12,
          position: "sticky", // Make the header sticky
      top: 0, // Stick the header to the top
          // textAlign: "center",
          // fontWeight: "bold",
          // minHeight:"50em"
        },
        maxBodyHeight: "1000px",
        pageSize: 100,
        fixedHeader: true,
        actionsColumnIndex: -1,

        rowStyle: rowData => {
          
          return {
          fontSize: 10,
          
        };
        } ,

      }}
      
      // options={{
      //   // paging: false,
      //   pageSize: 10,
      //   actionsColumnIndex: -1,
      //   // headerStyle: {
      //   //   backgroundColor:"#196c91",

      //   //   color: "#FFF",
      //   //   // fontSize: "17px",
      //   //   // textAlign: "center",
      //   //   fontWeight: "bold",
      //   //   // minHeight:"50em"
      //   // },
      //   headerStyle: {
      //     backgroundColor:"#196c91",
      //     color: "#FFF",
      //     fontSize: 10,
      //     // textAlign: "center",
      //     fontWeight: "bold",
      //     // minHeight:"50em"
      //   },
      //   // grouping: true,
      //   rowStyle: rowData => {
      
      //     // return {backgroundColor:rowData.rfd>0? "#ffffe0":"", };
      //     return {
      //       fontSize: 10,
            
      //     };
      //   }
      // }}
      // yhan se 
      // parentChildData={(row, rows) => 
      //   rows.find(a => a.id == row.parentId)
      
      // }
      // yhan tak 
      

      editable={{
        isEditable: () => false, // Disable default row editing
        onBulkUpdate: (changes) =>
          new Promise((resolve, reject) => {
            const rows=Object.values(changes)
            // console.log("check edit ",rows)

            const updatedRows = [...testParameter];
            let index;
            rows.map (emp=>{
              // console.log(emp.oldData.tableData.id)
              index=emp.oldData.tableData.id
              updatedRows[index]=emp.newData
              updatedRows[index].result_value =parseInt(emp.newData.result_value)           

            })
            settestParameter(updatedRows)
            // console.log(updatedRows)
          
            resolve();
          }),
      }}
      // components={{
      //   EditField: props => (
      //     <input
      //       {...props}
      //       style={{ ...props.style, fontSize: 5 }}
      //       onChange={e => props.onChange(e.target.value)}
      //     />
      //   ),
      // }}
      
    />
  }
   </GridItem>
   <GridItem md={4} lg={4}>
 
{/* <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
{/* <GridItem md={12} lg={12}  style={{display:"flex",flexDirection:"row"}}>
<div style={{width:"70%"}}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
      //  marginTop: 6,
      //  marginLeft:25
     }}>
       <b>Remarks (Internal Use)</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1,
           width:"90%"
         }}
         value={remarksInternal}
         onChange={(e) => {
           setRemarksInternal(e.target.value)
         }}
         placeholder="Remarks for Internal use only "
       />
       </span>
       </div>
       <div style={{width:"30%",}}>
       <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
            //  SeveRecordHandler()
            setopenCommentDialodBox(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "1.5em",
            //  padding: "2.5em",
            width:"100%",
            paddingTop:"6%",paddingBottom:"6%",
            //  height: "3.8em",
             marginLeft: "5%",
             marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
           }}>
                Report Preview
           </Button>
           </div>

       </GridItem> */}
                  
                  <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row"}}>

<div style={{width:"70%"}}>
                  <span style={{
    display: 'flex',
    flexDirection: "column",
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Remarks (Internal Use)</b>
    <TextareaAutosize
      rowsMax={4}
      style={{
        padding: '0.5em',
        height: '3em',
        marginLeft: 1
      }}
      // value={comments}
      value={remarksInternal}
      onChange={(e) => {
        setRemarksInternal(e.target.value)
      }}
      placeholder="Remarks for Internal use only "
    />
    </span>
    </div>
    <div style={{width:"30%",}}>
    <Button
        variant="contained"
        color="primary"
        onClick={() => {
   //  alert("hello")
         //  SeveRecordHandler()
        //  setopenCommentDialodBox(true)
        alert("working on it")
         
        }}

        // disabled={saveInvoice}
        style={{
          textAlign: 'center',
          // width: "1.5em",
         //  padding: "2.5em",
         width:"100%",
        //  paddingTop:"6%",paddingBottom:"6%",
         //  height: "3.8em",
          marginLeft: "5%",
          marginTop:"17%",
          fontSize:12,
         //  marginBottom:10
         backgroundColor:"#196c91"
        }}>
          Report Preview
        </Button>
        </div>
    </GridItem>
    <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row"}}>

<div style={{width:"70%"}}>
                  <span style={{
    display: 'flex',
    flexDirection: "column",
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Rejection Remarks </b>
    <TextareaAutosize
      rowsMax={4}
      style={{
        padding: '0.5em',
        height: '3em',
        marginLeft: 1
      }}
      // value={comments}
      value={rejectionRemarks}
      onChange={(e) => {
        setRejectionRemarks(e.target.value)
      }}
      placeholder="Remarks for Internal use only "
    />
    </span>
    </div>
    <div style={{width:"30%",}}>
    <Button
        variant="contained"
        color="primary"
        onClick={() => {
   //  alert("hello")
         //  SeveRecordHandler()
        //  setopenCommentDialodBox(true)
        alert("working on it")
        // setopenSecondPageBox(false)
         
        }}

        // disabled={saveInvoice}
        style={{
          textAlign: 'center',
          // width: "1.5em",
         //  padding: "2.5em",
         width:"100%",
        //  paddingTop:"6%",paddingBottom:"6%",
         //  height: "3.8em",
          marginLeft: "5%",
          marginTop:"17%",
          fontSize:12,
         //  marginBottom:10
         backgroundColor:"#196c91"
        }}>
          Reject to phlebotomy
        </Button>
        </div>
    </GridItem>
                
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",}}>

   <div style={{width:"70%"}}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
      //  marginTop: 6,
      //  marginLeft:25
     }}>
       <b>Comments</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1
         }}
         value={comments}
         onChange={(e) => {
           setComments(e.target.value)
         }}
         placeholder="Comments to Print Ends of Reports"
       />
       </span>
       </div>
       <div style={{width:"30%",}}>
       <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
            //  SeveRecordHandler()
            setopenCommentDialodBox(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"100%",
          fontSize:12,

            paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
             marginLeft: "5%",
             marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
           }}>
                Comments
           </Button>
           </div>
       </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"5%"}}>
       <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",width:150,fontSize:10}}
            >
 <FormControlLabel
 style={{fontSize:10}}
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label={ <Typography style={{ fontSize: 12,fontWeight:"bold" }}>
              Scan PDF
            </Typography>}
            />
            
          
            </Button>
            {
            !pdfScanFlag
            ?
            <div style={{}}>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2.5%",
              paddingBottom:"2.5%",marginLeft:"2.5%",
              backgroundColor:"#196c91"
            }}
            

          >
            <p style={{ fontSize: 12,fontWeight:"bold" }}>Attach PDF</p>
            <input type="file" variant="contained"
              // color="primary"
              style={{width:"60%"}}
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF"
               name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
              // hidden // This hides the native input element


            ></input>
          </Button>
          </div>
          :<></>
}
{
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              // console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{
              paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button>
          </>
          :
          null
         }

   
    </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"5%"}}>
       {/* <div style={{width:"20%"}}> */}
                          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",width:150}}
            >
 <FormControlLabel
              control={<Checkbox checked={prevResultCheckBox} onChange={(event)=>{
                setprevResultCheckBox(event.target.checked)
              }} name="Prev Result" />}
              // label="Prev Result"
              label={ <Typography style={{ fontSize: 12,fontWeight:"bold" }}>
             Prev Result
            </Typography>}
            />
            
          
            </Button>
            {/* </div> */}
        
       <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={
              {
                // paddingTop:"4%",paddingBottom:"4%",backgroundColor:"#196c91",width:"80%"
                paddingTop:"2%",
              paddingBottom:"2%",
              marginLeft:"2.5%",
              backgroundColor:"#196c91"

              }}
          >
            {/* <b> Image</b> */}
            <p style={{ fontSize: 12,fontWeight:"bold" }}> Image</p>
            <input type="file" variant="contained"
              color="primary"
              style={{width:"60%"}}
              
              onChange={(e) => { handleScanImage(e) }}
              id="ScanImage" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            ></input>
          </Button>
         
                 </GridItem>
                 <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"5%"}}>
                 <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
      // for branch selection 
      if(localStorage.getItem('branch_name')){

        

var arrparameterid = []
var arrgrouptitle = []
var arrparametertitle = []
var arrapplygender = []
var arrapplyage = []
var arrresultvalue = []
var arrresultstatus = []
var arrtestunit = []
var arrtestrefrange = []
var arrtestparagraph = []
var arrprevious1 = []
var arrprevious2 = []
// 27-nov-2024 
var arrpostiveNegative = []

      var obj ={}
   var data= testParameter?.map((item,index)=>{
console.log("value in testParameter is "+JSON.stringify(item))
arrparameterid.push(item.aux_id)
arrgrouptitle.push("")

arrparametertitle.push(item.parameter_title)
arrapplygender.push(item.apply_gender)
arrapplyage.push(item.apply_age)
arrresultvalue.push(item.result_value=="-"?"":item.result_value)
arrresultstatus.push(item.result_status)
arrtestunit.push(item.test_units)
arrtestrefrange.push(item.ref_range)
arrtestparagraph.push(item.test_paragraph)
arrprevious1.push(item.previous_result_1)
arrprevious2.push(item.previous_result_2)
// 27-nov-2024 

arrpostiveNegative.push(item.category?item.category:"")

delete item.tableData
// return item
      })
      console.log({
       
        
      })
      // console.log(arrparameterid)

      // console.log("value delete change"+JSON.stringify(testParameter))
      testParametersFull.parameters_obj=testParameter
      var obj = {
        aux_id:selectedTestAuxId,
        remarks_tests:remarksInternal,
        comments_tests:comments.toString(),
        if_updated:ifUpdated,
        if_auto_LIMS:ifAutoLims,
        user_id:localStorage.getItem('user_id'),
        user_name:localStorage.getItem('name'),
        PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
        PDF_Name: ScanPDF != "" ? name+".pdf" : "",
        Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
        Image_Name: ScanImage != "" ? name + ".png" : "",
        arr_paramter_id:arrparameterid,
        arr_group_title:arrgrouptitle,
        arr_paramter_title:arrparametertitle,
        arr_apply_gender:arrapplygender,
        arr_apply_age:arrapplyage,
        arr_result_value:arrresultvalue,
        // arr_result_status:arrresultstatus,
        arr_result_status:arrpostiveNegative,
        arr_test_unit:arrtestunit,
        arr_test_ref_range:arrtestrefrange,
        arr_test_paragraph:arrtestparagraph,
        arr_previous_1:arrprevious1,
        arr_previous_2:arrprevious2
      
      }
      console.log("arrpostiveNegative "+JSON.stringify(arrpostiveNegative))

       console.log("checj obj "+JSON.stringify(obj))
       alert("Successfully done ")

       setopenSecondPageBox(false)
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
          method: "POST",
          body: JSON.stringify(obj)
      }).then((res) => res.json()).then((response) => {
        console.log(response)
        if(response.transaction_status=="Successful"){
          alert("Successfully done "+JSON.stringify(response))
          window.location.reload();
          

        }
        else{
          alert("Result Feeding Failure Tray Again ! "+response.transaction_status)
          window.location.reload();

        }
      })






      // settestParametersFull(...testParametersFull)

      // console.log("parameter that will change"+JSON.stringify(testParameter))
      // console.log("parameter Full new"+JSON.stringify(testParametersFull))

            //  SeveRecordHandler()

    }else{
      alert("Please Select Branch !")
    }
            
           }}
   
           // disabled={saveInvoice}
           style={{
            width:"40%",
            //  textAlign: 'center',
            //  width: "1.5em",
             padding: "2em",
             paddingLeft:"3em",
             paddingRight:"3em",
            // paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
            //  marginLeft: "3.5em",
            //  marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Save Records
           </Button>
           <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
      // for branch selection 
//       if(localStorage.getItem('branch_name')){

        

// var arrparameterid = []
// var arrgrouptitle = []
// var arrparametertitle = []
// var arrapplygender = []
// var arrapplyage = []
// var arrresultvalue = []
// var arrresultstatus = []
// var arrtestunit = []
// var arrtestrefrange = []
// var arrtestparagraph = []
// var arrprevious1 = []
// var arrprevious2 = []
//       var obj ={}
//    var data= testParameter.map((item,index)=>{
// // console.log("value in testParameter is "+JSON.stringify(item))
// arrparameterid.push(item.aux_id)
// arrgrouptitle.push("")

// arrparametertitle.push(item.parameter_title)
// arrapplygender.push(item.apply_gender)
// arrapplyage.push(item.apply_age)
// arrresultvalue.push(item.result_value)
// arrresultstatus.push(item.result_status)
// arrtestunit.push(item.test_units)
// arrtestrefrange.push(item.ref_range)
// arrtestparagraph.push(item.test_paragraph)
// arrprevious1.push(item.previous_result_1)
// arrprevious2.push(item.previous_result_2)



// delete item.tableData
// // return item
//       })
//       console.log({
       
        
//       })
//       // console.log(arrparameterid)

//       // console.log("value delete change"+JSON.stringify(testParameter))
//       testParametersFull.parameters_obj=testParameter
//       var obj = {
//         aux_id:selectedTestAuxId,
//         remarks_tests:remarksInternal,
//         comments_tests:comments.toString(),
//         if_updated:ifUpdated,
//         if_auto_LIMS:ifAutoLims,
//         user_id:localStorage.getItem('user_id'),
//         user_name:localStorage.getItem('name'),
//         PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
//         PDF_Name: ScanPDF != "" ? name+".pdf" : "",
//         Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
//         Image_Name: ScanImage != "" ? name + ".png" : "",
//         arr_paramter_id:arrparameterid,
//         arr_group_title:arrgrouptitle,
//         arr_paramter_title:arrparametertitle,
//         arr_apply_gender:arrapplygender,
//         arr_apply_age:arrapplyage,
//         arr_result_value:arrresultvalue,
//         arr_result_status:arrresultstatus,
//         arr_test_unit:arrtestunit,
//         arr_test_ref_range:arrtestrefrange,
//         arr_test_paragraph:arrtestparagraph,
//         arr_previous_1:arrprevious1,
//         arr_previous_2:arrprevious2
      
//       }
//       //  console.log("checj obj "+JSON.stringify(obj))
//       //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
//       //     method: "POST",
//       //     body: JSON.stringify(obj)
//       // }).then((res) => res.json()).then((response) => {
//       //   console.log(response)
//       //   alert("Successfully done "+JSON.stringify(response))
//       // })





//       // settestParametersFull(...testParametersFull)

//       // console.log("parameter that will change"+JSON.stringify(testParameter))
//       // console.log("parameter Full new"+JSON.stringify(testParametersFull))

//             //  SeveRecordHandler()

//     }else{
//       alert("Please Select Branch !")
//     }
if(localStorage.getItem('branch_name')){

        

  var arrparameterid = []
  var arrgrouptitle = []
  var arrparametertitle = []
  var arrapplygender = []
  var arrapplyage = []
  var arrresultvalue = []
  var arrresultstatus = []
  var arrtestunit = []
  var arrtestrefrange = []
  var arrtestparagraph = []
  var arrprevious1 = []
  var arrprevious2 = []
  // 27-nov-2024 
  var arrpostiveNegative = []
  
        var obj ={}
     var data= testParameter?.map((item,index)=>{
  console.log("value in testParameter is "+JSON.stringify(item))
  arrparameterid.push(item.aux_id)
  arrgrouptitle.push("")
  
  arrparametertitle.push(item.parameter_title)
  arrapplygender.push(item.apply_gender)
  arrapplyage.push(item.apply_age)
  // arrresultvalue.push(item.result_value)
arrresultvalue.push(item.result_value=="-"?"":item.result_value)

  arrresultstatus.push(item.result_status)
  arrtestunit.push(item.test_units)
  arrtestrefrange.push(item.ref_range)
  arrtestparagraph.push(item.test_paragraph)
  arrprevious1.push(item.previous_result_1)
  arrprevious2.push(item.previous_result_2)
  // 27-nov-2024 
  
  arrpostiveNegative.push(item.category?item.category:"")
  
  delete item.tableData
  // return item
        })
        console.log({
         
          
        })
        // console.log(arrparameterid)
  
        // console.log("value delete change"+JSON.stringify(testParameter))
        testParametersFull.parameters_obj=testParameter
        var obj = {
          aux_id:selectedTestAuxId,
          remarks_tests:remarksInternal,
          comments_tests:comments.toString(),
          if_updated:ifUpdated,
          if_auto_LIMS:ifAutoLims,
          user_id:localStorage.getItem('user_id'),
          user_name:localStorage.getItem('name'),
          PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
          PDF_Name: ScanPDF != "" ? name+".pdf" : "",
          Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
          Image_Name: ScanImage != "" ? name + ".png" : "",
          arr_paramter_id:arrparameterid,
          arr_group_title:arrgrouptitle,
          arr_paramter_title:arrparametertitle,
          arr_apply_gender:arrapplygender,
          arr_apply_age:arrapplyage,
          arr_result_value:arrresultvalue,
          // arr_result_status:arrresultstatus,
          arr_result_status:arrpostiveNegative,
          arr_test_unit:arrtestunit,
          arr_test_ref_range:arrtestrefrange,
          arr_test_paragraph:arrtestparagraph,
          arr_previous_1:arrprevious1,
          arr_previous_2:arrprevious2
        
        }
        console.log("arrpostiveNegative "+JSON.stringify(arrpostiveNegative))
  
         console.log("checj obj "+JSON.stringify(obj))
         
       

        alert("Use Save Rocords Button !")
         
        //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
        //     method: "POST",
        //     body: JSON.stringify(obj)
        // }).then((res) => res.json()).then((response) => {
        //   console.log(response)
        //   alert("Successfully done "+JSON.stringify(response))
        // })
  
  
  
  
  
        // settestParametersFull(...testParametersFull)
  
        // console.log("parameter that will change"+JSON.stringify(testParameter))
        // console.log("parameter Full new"+JSON.stringify(testParametersFull))
  
              //  SeveRecordHandler()
  
      }else{
        alert("Please Select Branch !")
      }
            
           }}
   
           // disabled={saveInvoice}
           style={{
            width:"100%",

            //  textAlign: 'center',
            //  width: "1.5em",
             padding: "2em",
             paddingLeft:"3em",
             paddingRight:"3em",
            // paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             marginLeft: "5%",
            //  marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Save & Update Feed
           </Button>
                 </GridItem>
                    
  </GridItem>
  </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenSecondPageBox(false)
              // alert(" ki hoya")

              
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
            {/* comment box open */}
            <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'sm'}
        fullWidth={true}
        open={openCommentDialodBox}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
      setopenCommentDialodBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Select Comments</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
              <div className={classes.paper}>
        <MaterialTable
            columns={[

              { title: "Code", field: "comment_code",width: "10%"  },
              { title: "Text", field: "comment_text",width: "70%"  },
              { title: "Select", field: "select",width: "20%"  },

             
            ]}
            data={allComments}
            title={"Comments"}
          />
        </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenCommentDialodBox(false)

              
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>

        </>
    );
}
