import React from 'react';
import {View, StyleSheet, Text  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
       // marginTop: 30,
    
        width:"380em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View>
   {/* <View style={styles.tableContainer}> */}
   {/* <View style={[{ marginTop:invoice.if_panelMR_cnic_father? 50:30},styles.tableContainer]}> */}
   {/* <View style={[{ marginTop:invoice.cnic!=""&&invoice.father_husband=="null"&&invoice.if_panelMR_cnic_father==true ? 40:invoice.father_husband!="null"&&invoice.cnic==""&&invoice.if_panelMR_cnic_father==true? 40:invoice.father_husband!="null" &&invoice.cnic!=""&&invoice.if_panelMR_cnic_father==true ?50:30},styles.tableContainer]}> */}
   <View style={[{
     marginTop:invoice.cnic!=""&&invoice.father_husband=="null"&&invoice.if_panelMR_cnic_father==true ? 10:invoice.father_husband!="null"&&invoice.cnic==""&&invoice.if_panelMR_cnic_father==true? 10:invoice.father_husband!="null" &&invoice.cnic!=""&&invoice.if_panelMR_cnic_father==true ?20:0},styles.tableContainer]}>

   <InvoiceTableHeader />
   <InvoiceTableRow items={invoice.reports} key={invoice.name} invoice={invoice} />
   <View style={{ }}>
        
        <View style={ {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
       
        }} key={"q123"}>
              <Text style={{ 

width: '50%',
textAlign: 'left',
borderColor: 'black',
borderWidth: 0.5,
height:'100%',

fontSize:6.5,
padding: 5,

}}>
    {invoice.Invoice_Remarks}
</Text>
<Text style={{ 

width: '5%',
textAlign: 'left',
height:'100%',

fontSize:6.5,
padding: 5,

}}>
  

</Text>
                  <Text style={{ 
                          
                 width: "45%",
                 textAlign: 'left',
                 height:'100%',
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{""}</Text>
                     

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"grey"
        }} key={"2w1"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:5,
flexDirection:"column"

}}>
    {/* 21-jan */}
                            <Text style={{ fontSize: 7 }}>
                                User : {invoice.User_Name}
                            </Text>
                            <Text style={{ fontSize: 7,}}>
                            Date/Time : {invoice.invoice_date} {invoice.invoice_time} Hrs
                            </Text>
                           
                          

</View>
<Text style={{ 
                          
                          width: "45%",
                          textAlign: 'left',
                          height:'100%',
                          fontSize:6.5,
                          padding: 5,
                          
                          
                           }}>{""}</Text>

            </View>
    {/* 21-jan */}

            {/* <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>
      <Text style={{ fontSize: 7 }}>
                            Date/Time : {invoice.invoice_date} {invoice.invoice_time} Hrs
                            </Text>
                           

</Text>
<Text style={{ 
                          
                          width: "45%",
                          textAlign: 'left',
                          height:'100%',
                          fontSize:6.5,
                          padding: 5,
                          
                          
                           }}>{""}</Text>

            </View> */}
            {/* <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
        backgroundColor:"grey"
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>
    {
    invoice.flight_no!="" ?
                 
      <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
   fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
   {"I have read and verified my particulars as correct"}
   </Text>
  
               :
               null
    }

</Text>
<Text style={{ 
                          
                          width: "45%",
                          textAlign: 'left',
                          height:'100%',
                          fontSize:6.5,
                          padding: 5,
                          
                          
                           }}>{""}</Text>

            </View> */}
            {/* <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
        backgroundColor:"grey"
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>
      {
    invoice.flight_no!="" ?
                 
    <Text style={{ 
           
        textAlign: 'left',
        padding:2, 
        fontSize:6.5,
        color:'black'
        }}>
        {"Name : "+invoice.name}
        </Text>
  
               :
               null
    }

</Text>
<Text style={{ 
                          
                          width: "45%",
                          textAlign: 'left',
                          height:'100%',
                          fontSize:6.5,
                          padding: 5,
                          
                          
                           }}>{""}</Text>

            </View> */}
            </View>
            
            
</View>
{
    invoice.flight_no!="" ?
    <View style={{
        marginLeft:'2em'
    }}> 
  
      
               <View>
              
                    
             
           <Text style={{ 
           
           textAlign: 'left',
           padding:2, 
           fontSize:6.5,
           color:'black'
           }}>
           {"CNIC : "+invoice.cnic}
           </Text>
           {
               invoice.flight_no!="" ?
               <Text style={{ 
           
                   textAlign: 'left',
                   padding:2, 
                   fontSize:6.5,
                   color:'black'
                   }}>
                   {"Passport No : "+invoice.passport_no}
                   </Text>
                   : null
           }
      
           
          
                       </View>
        <View style={{
            height:30
        }} >
              
                     <Text style={{ 
                             
                    width: '40%',
                    textAlign: 'left',
                    borderColor: 'black',
                    borderWidth: 0.5,
                    fontSize:6.5,
                    padding: 7,
                    
                    
                     }}>{"Signature"}</Text>
                       
      <Text style={{ 
   
   width: '60%',
   textAlign: 'left',
  
   padding:5,  
   fontSize:7,
   }}>
   
   </Text>
   
               </View>
               <View style={{paddingLeft:10, paddingTop:5}}>
     <Text style={{
        fontFamily: 'Helvetica-Bold'
     }}>Note:</Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Reporting time is subject to the sample submission.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Please bring this form to collect the reports.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>In case of any query regarding report, please call at the given number within 48 hours.</Text></Text>
     </View>
               </View>
     :
     null
}
</View>
 
  
  );
  
  export default InvoiceItemsTable