import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily:'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items}) => {
    var rows=[] 
    var total_bill=0
    var counter=0
    items.map( item => {
        total_bill=total_bill+parseInt(item.Test_Price)
        counter=counter+1
        return rows.push(<View style={ {
        
            flexDirection: 'row',
            borderBottomColor: 'BLACK',
            borderBottomWidth:1,
            fontSize:9,
            alignItems: 'center',
            width:"100%",
            fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{ 
                     
                     width: '5%',
                     textAlign: 'left',
                     borderLeftColor: 'black',
                     borderLeftWidth: 1,
                     paddingLeft: 8,
                     height:'100%',
                     
                     
                     }}>{counter}</Text>

                 <Text style={{ 
                     
            width: '10%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%',
            
            
            }}>{item.id}</Text>
             <Text style={{ 
                     
            width: '20%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 8
            
            
            }}>{item.name}</Text>
             <Text style={{ 
                     
            width: '20%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%'
            
            
            }}>{item.age_gender}</Text>
             <Text style={{ 
                     
            width: '30%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            }}>{item.test_name}</Text>
             <Text style={{ 
                     
            width: '15%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderRightWidth:1,
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            
            }}>{item.sample_date_time}</Text>
               
            </View>)
            }
    )
   
    

    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow