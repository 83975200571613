import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice, discount, panel}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableRow items={invoice} discount={discount} panel={panel} />
    </View>
  );
  
  export default InvoiceItemsTable