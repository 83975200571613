import React from 'react';
import {View, StyleSheet, Text  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 3,
        width:"100em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View>
   <View style={styles.tableContainer}>
   <InvoiceTableHeader />
   {/* invoice.reports me sary test one by one likhen hn with fee ,disc,netPrice that are pass to InvoiceTableRow */}
   <InvoiceTableRow items={invoice.reports} key={invoice.name} />
   {/* jab sary test one by one print ho gae then jo niche NetCost etc  */}
   <View>
        
        <View style={ {
        flexDirection: 'row',
        marginTop:1,
        alignItems: 'center',
        width:"100%",
        height:10,
        fontSize:4,
        fontStyle: 'bold'
        }} key={"q123"}>
       
                  <Text style={{ 
                          
                 width: '50%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:4,
                 padding: 1,
                 
                 
                  }}>{"Net Cost"}</Text>
                     <Text style={{ 
                          
                 width: '50%',
                 height:'100%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 fontSize:5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 1
                 
                 }}>{invoice.Invoice_Value}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        marginTop:1,
        alignItems: 'center',
        width:"100%",
        height:10,
        fontSize:4,
        fontStyle: 'bold'
        }} key={"q123"}>
       
                  <Text style={{ 
                          
                 width: '50%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:4,
                 padding: 1,
                 
                 
                  }}>{"Discount"}</Text>
                     <Text style={{ 
                          
                 width: '50%',
                 height:'100%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 fontSize:5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 1
                 
                 }}>{invoice.Discount_Total}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        marginTop:1,
        alignItems: 'center',
        width:"100%",
        height:10,
        fontSize:4,
        fontStyle: 'bold'
        }} key={"q123"}>
       
                  <Text style={{ 
                          
                 width: '50%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:4,
                 padding: 1,
                 
                 
                  }}>{"Invoice Net Payable"}</Text>
                     <Text style={{ 
                          
                 width: '50%',
                 height:'100%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 fontSize:5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 1
                 
                 }}>{invoice.Invoice_Payable}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        marginTop:1,
        alignItems: 'center',
        width:"100%",
        height:10,
        fontSize:4,
        fontStyle: 'bold'
        }} key={"q123"}>
       
                  <Text style={{ 
                          
                 width: '50%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:4,
                 padding: 1,
                 
                 
                  }}>{"Total Received Value"}</Text>
                     <Text style={{ 
                          
                 width: '50%',
                 height:'100%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 fontSize:5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 1
                 
                 }}>{invoice.Invoice_Received}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        marginTop:1,
        alignItems: 'center',
        width:"100%",
        height:10,
        fontSize:4,
        fontStyle: 'bold'
        }} key={"q123"}>
       
                  <Text style={{ 
                          
                 width: '50%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:4,
                 padding: 1,
                 
                 
                  }}>{"Balance Value"}</Text>
                     <Text style={{ 
                          
                 width: '50%',
                 height:'100%',
                 textAlign: 'center',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 fontSize:5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 1
                 
                 }}>{invoice.Invoice_Balance}</Text>

            </View>
           
            </View>
    </View>
    
</View>
 
  
  );
  
  export default InvoiceItemsTable