import React,{useState} from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../mpl-logo.png'



const styles = StyleSheet.create({
    page: {
        paddingTop: 112,
        // paddingTop: 120.3, point me value ni deni

        paddingBottom: 40,
        fontSize: 8,
        paddingLeft: 10,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 130,
        height: 60,
        marginTop: -70
    },
    barcode: {
        width: 145,
        height: 18,
        marginLeft: '5em'
    }
});


const Invoice = ({ invoice }) => {
    // 21-jan
    var counter=0
   
   
    return (<Document >
        <Page style={styles.page} size="A5" wrap >
        
            <View fixed style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 10,
                //   backgroundColor:"grey"

            }} >

                <View
                    style={{
                        width: "30%",
                        // backgroundColor:"green"

                    }}
                >
                    <Image style={{

                        width: 180,
                        height:  90,
                        marginTop: -30,
                        marginLeft: -25

                    }} src={logo} />
                    {/* <View style={{
                        width:"300%",
                        marginTop:15,
                        marginLeft:-5
                    }}>
                     <InvoiceTitle item={invoice} />
                   
                     </View> */}
                    
                </View>
                <View style={{position:"absolute",top:76, left:16
                       }}>
               
                <Text style={{
                     paddingTop: '5em', fontSize: 11,fontWeight:"bold",
                       
                       
                    }}>{invoice.headingPrint}</Text>
                    </View>

                <View style={{ marginTop: -40, marginLeft: 10, width: "40%" , flexDirection:'column'}} >
                <Text style={{ marginLeft:-8, textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 12 }}>RECEIPT</Text>
                <Text style={{ paddingLeft: '1.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7.5, textDecoration:'underline' }}>Outstanding Invoices</Text>
                   
                   
                </View>
                <View style={{ width: "30%" ,marginTop:50}}  >
               
                   
                   <View style={{width:"85%" ,flexDirection:"row", borderBottomColor: 'black',
                        borderBottomWidth: 0.75,}}>
                   <Text style={{
                         paddingTop: '5em', fontSize: 7,
                       
                    }}>Rec   :</Text>
                <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                       
                       
                    }}>  {invoice.rec}</Text>
                    </View>
                    <View style={{width:"85%" ,flexDirection:"row", borderBottomColor: 'black',
                        borderBottomWidth: 0.75,}}>
                   <Text style={{
                         paddingTop: '5em', fontSize: 7,
                       
                    }}>Date   :</Text>
                <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        paddingRight:"8em"
                    }}>  {invoice.date}</Text>
                    </View>           
                </View>
            </View>
            
         
         
            <View style={{ width: '100%', height: "100%",
            //ye marginTop khtm kiya 19-jan
            //  marginTop:invoice.Panel_Name!=""?20: 0, //ye agr arid uni ki report me aaya to es ko uncommit kr dena ahi aur cond lga deni hai ager arid uni ho to 20 ho jae
           // 23-jan marginTop:invoice.Panel_Name!=""?10: 0,
        //    invoice.Panel_Name!="" &&invoice.reports.length>7?18:invoice.Panel_Name!="" &&invoice.reports.length<7? 5 ye condition hai jab 
                  
            // marginTop: 11,
            //   marginTop: -50,
            //  marginBottom:"10%",
            //  backgroundColor:"red",
           
            //  margin wale isssue ko resolve krny k lye yhan se margin Top:0 kr den aur InvoiceItemTable pe marginTop 40 kr den 
//   marginTop: 0,

              paddingRight: 5, paddingLeft: 5}}

            >

                <InvoiceItemsTable invoice={invoice}
                />

            </View>

              

              <Text fixed style={{ width: "100%", left: 0, paddingLeft: 10, position: "absolute", fontSize: 6,top:560 ,color:"black"}} render={({ pageNumber, totalPages }) => (
                            "Page " + `${pageNumber} / ${totalPages}`
                            
                        )} />
        </Page>
    </Document>
    )
}

export default Invoice