import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#696969',
        backgroundColor: '#696969',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Helvetica-Bold',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    description: {
        width: '25%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    sn: {
        width: '5%',
    },
    id: {
        width: '8%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    name: {
        width: '17%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    cnic: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    title: {
        width: '22%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    branch: {
        width: '5%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    }, 
    status: {
        width: '9.6%',
        margin:"auto",
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    status1: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    }

  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
         <Text style={styles.sn}>SR</Text>
        <Text style={styles.id}>INVOICE ID</Text>
        <Text style={styles.name}>NAME</Text>
        <Text style={styles.title}>TEST NAME</Text>
        <Text style={styles.status}>T.AT (Rs)</Text>
        <Text style={styles.status}>MPL/S (Rs)</Text>
        <Text style={styles.status}>FRAN/S (Rs)</Text>
        <Text style={styles.status}>DIS (Rs)</Text>
        <Text style={styles.status}>NT.REV (Rs)</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader