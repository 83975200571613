import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Calibri Regular',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    description: {
        width: '30%',
        fontSize:8,
        paddingTop:2
    },
    qty: {
        width: '25%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },
    rate: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        paddingTop:2,
        
        fontSize:8
    },
    prev1: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },
    prev2: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },
  });

  const InvoiceTableHeader = ({date}) => (
    <View style={styles.container}>
        <Text style={styles.description}>Test Name</Text>
  <Text style={styles.qty}>{"Result "+date}</Text>
        <Text style={styles.rate}>Ref Range</Text>
        <Text style={styles.prev1}>Previous 1</Text>
        <Text style={styles.prev2}>Previous 2</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader