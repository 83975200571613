import React from 'react';
import {View, StyleSheet, Text, Image  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        // marginTop: 3,
        width:"380em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View>
      <InvoiceTitle item={invoice} />
   <View style={styles.tableContainer}>
   {/* <View style={[{ marginTop:invoice.if_panelMR_cnic_father? 40:3},styles.tableContainer]}> */}

   <InvoiceTableHeader />
   <InvoiceTableRow items={invoice.reports} key={invoice.name} invoice={invoice} />
   { invoice.Patient_Comments!="" ?
   <View style={ {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"q123"}>
   <Text style={{ 

width: '50%',
textAlign: 'left',
borderColor: 'black',
borderWidth: 0.5,
height:'100%',

fontSize:6.5,
padding: 5,

}}>
    {invoice.Patient_Comments}

</Text>
<Text style={{ 

width: '50%',
textAlign: 'left',
height:'100%',

fontSize:6.5,
padding: 5,

}}>
  

</Text>
</View>
:null}
   {
                invoice.flight_no ?
                    <View>
                        <View style={{
                            flexDirection: 'row',
                            marginTop: 5,
                            alignItems: 'center',
                            width: "100%",
                            height: 15,
                          
                        }} key={"q123"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,

                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                                


                            }}>{"Flight No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                                

                            }}>{invoice.flight_no}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} >
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Airline"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{invoice.Airline}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={"21"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Ticket No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{invoice.Ticket_No}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={"21"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Flight Date"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{invoice.flight_date}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={"43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                               
                              


                            }}>{"Flight Time"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                              

                            }}>{invoice.flight_time}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={"43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                               


                            }}>{"Passport No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{invoice.passport_no}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 20
                           
                        }} key={"43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                              


                            }}>{"Destination"}</Text>
                             <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{invoice.Destination}</Text>

                        </View>
                        <View key={"4323"} style={{marginTop:15}}>


<Text style={{

    textAlign: 'left',
    padding: 2,
    fontFamily: 'Helvetica-Bold',
    fontSize: 6.5,
    color: 'black'
}}>
    {"I have read and verified my particulars as correct"}
</Text>

</View>
<View key={"4323"}>


<Text style={{

    textAlign: 'left',
    padding: 2,
    fontSize: 6.5,
    color: 'black'
}}>
    {"Name : " + invoice.name}
</Text>
<Text style={{

    textAlign: 'left',
    padding: 2,
    fontSize: 6.5,
    color: 'black'
}}>
    {"CNIC : " + invoice.cnic}
</Text>

{
    invoice.flight_no != ""
        ?
        <Text style={{

            textAlign: 'left',
            padding: 2,
            fontSize: 6.5,
            color: 'black'
        }}>
            {"Passport No : " + invoice.passport_no}
        </Text>
        : null
}



</View>

<View key={"542343"} >

<Text style={{

    width: '40%',
    textAlign: 'left',
    borderWidth: 0.5,
    borderColor: 'black',
    fontSize: 6.5,
    padding: 5,


}}>{"Signature"}</Text>

<View style={{

width: '60%',
textAlign: 'left',
height: '100%',
padding: 5,
fontSize: 7,
}}>
{
        invoice.Base64_PicturePatient!="data:image/png;base64,"
        ?
        <View style={{
            display:'flex',
            width:"100%",
            flexDirection:"row",
            alignItems:'center',
            position:'absolute',
        }}>
           
              <View style={{
                width:"50%",
                flexDirection:"column",
                textAlign:"left",
                fontFamily: 'Helvetica-Bold',
                padding:5
            }}>
                <Text >Patient Image</Text>
                {
                    invoice.Base64_PicturePatient=="data:image/png;base64,"
                    ?
                    <Text
                    style={{
                     fontFamily: 'Helvetica-Bold',
                     fontSize: 9,
                     textAlign:"center"
                 }}
                    >No Patient Image Found</Text>
                    :
                    <Image style={{
                     width:100,
                     height:100
                 }} src={invoice.Base64_PicturePatient} />
                }
              
                  </View>
                  <View style={{
                    width:"50%",
                    flexDirection:"column",
                    textAlign:"center",
                    padding:5,
                    fontFamily: 'Helvetica-Bold'
                }}>
                    <Text >Passport Image</Text>
                    {
                        invoice.Base64_ImagePassport=="data:image/png;base64,"
                        ?
                        <Text
                        style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 9,
                         textAlign:"center"
                     }}
                        >No Passport Image Found</Text>
                        :
                        <Image style={{
                         width:180,
                         height:120,
                         marginLeft:20
                     }} src={invoice.Base64_ImagePassport} />
                        }
                    </View>
              </View>
              :
              null
    }

        
      
</View>

</View>

</View>

 :
 null}  
</View>
     
</View>
 
  
  );
  
  export default InvoiceItemsTable