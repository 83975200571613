import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import ReactLoading from 'react-loading';

import TableContainer from '@material-ui/core/TableContainer';

import Grid from "@material-ui/core/Grid";
import TableHead from '@material-ui/core/TableHead';

import { PDFViewer, Font } from '@react-pdf/renderer'
import Invoice from './Pdf/Invoice.js'

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import LibraryBooks from "@material-ui/icons/LibraryBooks";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Invoice ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'age_gender', numeric: false, disablePadding: false, label: 'Age / Gender' },
  { id: 'test_name', numeric: false, disablePadding: false, label: 'Test Name' },
  { id: 'sample_date_time', numeric: true, disablePadding: false, label: 'Sample Date & Time' }

];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: '#196c90',
        backgroundColor: lighten('#196c90', 0.85),
      }
      : {
        color: '#196c90',
        backgroundColor: '#196c90',
      },
  title: {
    flex: '1 1 100%',
  },
}));
var selected_LabTests = []

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { Add_Tests_to_List } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <></>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Select">
          <Button
            color="primary"
            style={{ backgroundColor: '#196c90', color: 'white', width:"15em" }}
            onClick={() => {
              Add_Tests_to_List()
            }}
          >
            Export List
                  </Button>
        </Tooltip>
      ) : (
          <></>
        )}
    </Toolbar>
  );
};

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return addZero(hours) + ':' + addZero(minutes) ;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default class TestSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 'asc',
      setOrder: 'asc',
      selected: [],
      setSelected: [],
      orderBy: 'calories',
      setOrderBy: 'calories',
      page: 0,
      setPage: 0,
      dense: false,
      rows: [],
      org_rows: [],
      setDense: false,
      rowsPerPage: 10,
      setRowsPerPage: 10,
      alert: false,
      loading: true


    }
    this.Add_Tests_to_List = this.Add_Tests_to_List.bind(this)
  }

  componentWillMount() {
    var patient_id = localStorage.getItem('patient_id')
    var role = localStorage.getItem('role')

    var branchID=localStorage.getItem('branch_id')
    var to = new Date();
    var from = new Date(to.getTime() - (5 * 24 * 60 * 60 * 1000));

    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    var vSearchStr = JSON.stringify(SearchFormData);

    var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole=" + role
    fetch(url).then((res) => res.json())
      .then((result) => {
       var the_rows=[]
       result.map((row)=>{
          if(row.Test_Status!="Only Phlebotomy Done"){
            return
          }

         var sample_date=new Date(row.ACCESS_DATE).ddmmyyy()
         var sample_time=msToTime(row.ACCESS_TiME)
         
         var obj={
          id:row.Invoice_ID,
          name:row.NAME_F+" "+row.NAME_L,
          age_gender:row.Age_gender,
          test_name:row.T_TiTLE,
          sample_date_time:sample_date+" "+sample_time
         }
         the_rows.push(obj)

       })
       this.setState({ rows: the_rows })
       this.setState({ org_rows: the_rows })
       this.setState({ loading: false })
      })

  }
  Add_Tests_to_List() {
    this.setState({ alert: true })
  }

  emptyRows = () => this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage)
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ setOrder: (isAsc ? 'desc' : 'asc') });
    this.setState({ setOrderBy: property })
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => n);
      this.setState({ selected: newSelecteds })
      selected_LabTests = newSelecteds
      return;
    }
    this.setState({ selected: [] })
  };
  classes = () => useStyles()

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    selected_LabTests = newSelected
    this.setState({ selected: newSelected })

  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: (parseInt(event.target.value, 10)) });
    this.setState({ page: 0 })
  };

  handleChangeDense = (event) => {
    this.setState({ setDense: event.target.checked });
  };

  isSelected = (name) => {

    return this.state.selected.indexOf(name) != -1;
  }

  render() {
    return (
      <div className={this.classes.root}>
        {this.state.loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <Paper className={this.classes.paper}>
           <center><h2><b>Work List</b></h2></center>
            <EnhancedTableToolbar numSelected={this.state.selected.length} Add_Tests_to_List={this.Add_Tests_to_List} />
            <TableContainer>
              <Table
                className={this.classes.table}
                aria-labelledby="tableTitle"
                size={this.state.dense ? 'small' : 'medium'}
                aria-label="enhanced table"

              >

                <EnhancedTableHead
                  classes={this.classes}
                  numSelected={this.state.selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}

                  rowCount={this.state.rows.length}
                />
                <TableBody>
                  {stableSort(this.state.rows, getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, row)}
                          role="checkbox"
                          aria-checked={this.isSelected(row)}
                          tabIndex={-1}

                          key={row.id}
                          selected={this.isSelected(row)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={this.isSelected(row)}
                              inputProps={{ 'aria-label-ledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.age_gender}</TableCell>
                          <TableCell align="left">{row.test_name}</TableCell>
                          <TableCell align="right">{row.sample_date_time}</TableCell>

                        </TableRow>

                      );
                    })}

                  {this.emptyRows() > 0 && (
                    <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows() }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.rows.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        }
        <Grid>
          <Dialog
            classes={{
              root: this.classes.center,
              paper: this.classes.modal
            }}
            open={this.state.alert}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
            fullWidth={true}
            onClose={() => this.setState({ alert: false })}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={this.classes.modalHeader}
            >
              <h3><b>Quotation</b></h3>

            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={this.classes.modalBody}

            >
              <center>
                {
                 this.state.selected.length>0 ?
                  <Fragment>
                  <PDFViewer width="1000" height="1000" className="app" >
                    <Invoice invoice={this.state.selected} />
                  </PDFViewer>
                </Fragment>
                :null
                }
              </center>
            </DialogContent>
            <DialogActions className={this.classes.modalFooter}>

              <Button
                onClick={() => this.setState({ alert: false })}
                color="danger"
                simple
              >
                <b>Close</b>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

      </div>
    );
  }
}
