import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import ReactLoading from 'react-loading';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Grid from "@material-ui/core/Grid";


import Slide from "@material-ui/core/Slide";

import MaterialTable from "material-table";
import { set } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));
const formatAMPM=(date)=>{
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}
export default function Accessioning({invoice_no,BoxClose}) {
  const classes = useStyles();
  const [loading,setLoading]=React.useState(false)
  const [patient_id,setPatientID]=React.useState(0)
  const [patient_name,setPatientName]=React.useState("")
  const [mobile_no,setMobileNo]=React.useState("")
  const [gender,setGender]=React.useState("")
  const [Samples,setSamples]=React.useState([])
  const [selectedSamples,setSelectedSamples]=React.useState([])
  const [selectedRobo7,setSelectedRobo7]=React.useState([])
  
 
useEffect(()=>{
  if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
    window.location.href="https://invoicing.mpl-labs.pk/login"
  }
  
  var obj={
    invoice_no:invoice_no
  }
  fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_AccessionInvoiceTestsCall", {
      method:"POST",
      body:JSON.stringify(obj)
  }).then(res=>res.json()).then((response)=>{
      setPatientID(response[0].patient_id)
      setPatientName(response[0].patient_name)
      setMobileNo(response[0].mobile_no)
      setGender(response[0].gender)
      var arr=JSON.parse(response[0].ArrayAuxID)
      var temp_samples=[]
      var counter=0
      arr.map((item)=>{
          temp_samples.push({
            ArrayAuxID:JSON.parse(response[0].ArrayAuxID)[counter],
              ArrayTestID:JSON.parse(response[0].ArrayTestID)[counter],
              ArrayTestCode:JSON.parse(response[0].ArrayTestCode)[counter],
              ArrayTestName:JSON.parse(response[0].ArrayTestName)[counter],
              ArrayTestUpdate:JSON.parse(response[0].ArrayTestUpdate)[counter],
              ArrayOST:JSON.parse(response[0].ArrayOST)[counter],
              ArrayReject:JSON.parse(response[0].ArrayReject)[counter],
              ArrayPlaceID:JSON.parse(response[0].ArrayPlaceID)[counter],
              ArrayPlaceName:JSON.parse(response[0].ArrayPlaceName)[counter]
          })
          counter=counter+1
      })
      setSamples(temp_samples)

    
    
  })
 
},[])

  
  return (
    <div className={classes.root}>
        {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <div>
            <Grid container >
            <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" label="Invoice No" value={invoice_no} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient ID" value={patient_id} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient Name" value={patient_name}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Mobile No" value={mobile_no}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Gender" value={gender} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      </Grid>
 <Grid container style={{
     justifyContent:"space-evenly"
 }}>
 <MaterialTable
 fullWidth={true}
      title="Samples"
      style={{
          width:"40em"
      }}
      columns={[
       { title: 'ID', field: 'ArrayAuxID' },
        { title: 'Code', field: 'ArrayTestCode' },
        { title: 'Name', field: 'ArrayTestName' }
    
      ]}
      onSelectionChange={(rows) => {
        setSelectedSamples(rows)
      }}
      data={Samples}        
      options={{
        selection: true
      }}
    />
  
 </Grid>

  
 <Button
                variant="contained"
                color="primary"
                style={{
                    padding:"1.5em",
                    fontSize:"1.3em"
                }}
                className={classes.button}
                onClick={()=>{
                  var res = window.confirm('Do you want to Do Accessioning???')
                        if (res) {
                          if(selectedSamples.length==0){
                            alert("No Sample Selected!!")
                          }else{
                            var ArrayAuxID=[]
                            var ArrayTestCode=[]
                            var ArrayTestID=[]
                            var ArrayTestName=[]
                            var ArrayTestUpdate=[]
                            var ArrayOST=[]
                            var ArrayReject=[]
                            var ArrayPlaceID=[]
                            var ArrayPlaceName=[]

                          selectedSamples.map((item)=>{
                          ArrayAuxID.push(item.ArrayAuxID)
                          ArrayTestCode.push(item.ArrayTestCode)
                          ArrayTestID.push(item.ArrayTestID)
                          ArrayTestName.push(item.ArrayTestName)
                          ArrayTestUpdate.push(true)
                          ArrayOST.push(false)
                          ArrayReject.push(false)
                          ArrayPlaceID.push(item.ArrayPlaceID)
                          ArrayPlaceName.push(item.ArrayPlaceName)

                          })
        
                          var obj={
                            invoice_no:invoice_no,
                            user_id:localStorage.getItem('user_id'),
                            user_name:localStorage.getItem('name'),
                            ArrayAuxID: ArrayAuxID,
                            ArrayTestCode:ArrayTestCode,
                            ArrayTestID: ArrayTestID,
                            ArrayTestName:ArrayTestName,
                            ArrayTestUpdate:ArrayTestUpdate,
                            ArrayOST:ArrayOST,
                            ArrayReject:ArrayReject,
                            ArrayPlaceID:ArrayPlaceID,
                            ArrayPlaceName:ArrayPlaceName
                          }

                  fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_AccessionInvoiceUpdate", {
                    method:"POST",
                    body:JSON.stringify(obj)
                }).then(res=>res.json()).then((response)=>{
                alert("Accessioning Done!!")
                    BoxClose()
                })
                        }
                        } else {
                         
                          return
                        }
                 


                  
                }}
              >
               Do Accessioning
              </Button>
</div>
     
}
</div>
  );
}
