import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#696969',
        backgroundColor: '#696969',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Helvetica-Bold',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    description: {
        // width: '20%',
        width: '18%',

        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    sn: {
        // width: '5%',
        width: '3%',

    },
    qty: {
        // width: '10%',
        width: '8%',

        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    rate: {
        // width: '25%',
        width: '18%',

        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    special_remarks: {
        width: '15.5%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    amount: {
        // width: '15%',
        width: '12.5%',

        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    prev1: {
        width: '8.3%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    }
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sn}>S/N</Text>
        <Text style={styles.description}>Test Name</Text>
        <Text style={styles.qty}>Test Code</Text>
        <Text style={styles.rate}>Sample Type</Text>
        <Text style={styles.special_remarks}>Special Remarks (If any)</Text>

        <Text style={styles.amount}>Delivery Time</Text>
        <Text style={styles.prev1}>Test Price</Text>
        <Text style={styles.prev1}>Discount</Text>
        <Text style={styles.prev1}>Net Price</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader