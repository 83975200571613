import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';

import DateFnsUtils from '@date-io/date-fns';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';


import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import Grid from "@material-ui/core/Grid";

import TextField from '@material-ui/core/TextField';

import GridItem from "../components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';

const columns = [
  { id: 'srno', label: 'Sr No' },
  { id: 'invoice_id', label: 'Lab#' },
  { id: 'UTF', label: 'UTF' },
  { id: 'hours', label: 'No of Hours' },
  { id: 'airline', label: 'Airline' },
  { id: 'destination', label: 'Destination' },
  { id: 'name', label: 'Patient Name' },
  { id: 'booking_date_time', label: 'Booking Date/Time' },
  { id: 'flight_no', label: 'Flight#' },
  { id: 'flight_date', label: 'Flight Date' },
  { id: 'passport', label: 'Passport' }

];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function SampleToTime(ms) {
  var seconds = (ms);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

function FlightToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function Reports() {
  const classes = useStyles();
  const [date, setDate] = React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [hoursFilter, sethoursFilter] = React.useState("All")

  //////////////////////
  const [TTCode, setTTCode] = React.useState([
    "Kotoka International Airport",
    " Kumasi Airport",
    " Navrongo Airport",
    " Takoradi Airport",
    " Sunyani Airport",
    " Tamale Airport",
    " Wa Airport",
    "Yendi Airport",
    "Bole International Airport (Addis Ababa Bole International Airport)",
    " Arba Minch Airport",
    " Asosa Airport",
    " Awasa Airport",
    " Axum Airport",
    " Bahir Dar Airport",
    " Beica Airport",
    " Kombolcha Airport",
    " Dansha Airport",
    " Debre Marqos Airport",
    " Debre Tabor Airport",
    " Dembidolo Airport",
    " Aba Tenna Dejazmach Yilma International Airport (Dire Dawa Int'l)",
    " Dodola Airport",
    " Dolo Airport",
    " Fincha Airport",
    " Gambela Airport",
    " Ghinnir Airport",
    " Robe Airport (Gobe Airport)",
    " Ugas Mirad Airport",
    " Gondar Airport",
    " Gore Airport",
    " Humera Airport",
    " Garaad Wiil-Waal Airport",
    " Aba Segud Airport (Jimma Airport)",
    " Baco Airport (Jinka Airport)",
    " Kabri Dar Airport",
    " Lalibela Airport",
    " Tum Airport",
    " Alula Aba Nega Airport (Makale Airport)",
    " Mekane Selam Airport",
    " Mendi Airport",
    " Genda Wuha Airport",
    " Mizan Teferi Airport",
    " Mui Airport",
    " Nejjo Airport",
    " Nekemte Airport",
    " Semera Airport",
    " Shakiso Airport",
    " Shilavo Airport",
    " Shire Airport",
    " Sodo Airport",
    "  Tippi Airport",
    " Wacca Airport",
    " Lideta Army Airport",
    " Harar Meda Airport",
    " Neghelle Airport",
    "Buffalo Range Airport", " Centenary Airport", " Charles Prince Airport",
    "Chipinge Airport", " Grand Reef Airport", " Gweru-Thornhill Air Base", "Hwange National Park Airport", " Hwange Town Airport", " Joshua Mqabuko Nkomo International Airport", " Kariba Airport", " Kwekwe East Airport", " Marondera Airport", " Masvingo Airport", " Mount Darwin Airport", " Mutare Airport", " Mutoko Airport", " Robert Gabriel Mugabe International Airport formerly Harare International Airport", " Victoria Falls Airport", " Zvishavane Airport",
    "Amboseli Airport", " Angama Mara Airport", " Bamburi Airport", " Bungoma Airport", " Bura East Airport", " Busia Airport", " Eldoret International Airport", " Eliye Springs Airport", " Embu Airport", "  Garba Tula Airport", " Garissa Airport", " Hola Airport", " Homa Bay Airport", "  Isiolo Airport", " Kakamega Airport", " Kakuma Airport", " Kalokol Airport", " Kericho Airport", " Kilaguni Airport", " Kimwarer Airport", " Kisii Airport",
    " Kisumu Airport", " Kitale Airport", "  Kiwayu Airport", " Lake Baringo Airport", " Manda Airport", " Lewa Airport", " Liboi Airport", " Lodwar Airport", " Loitokitok Airport", " Loitokitok Airport", " Loiyangalani Airport", " Lokichogio Airport", " Lokitaung Airport", " Mackinnon Road Airport", " Magadi Airport", " Makindu Airport", " Malindi Airport", " Mandera Airport", " Kisima Airport", " Marsabit Airport", " Keekorok Airport", " Mara Serena Airport", " Mulika Lodge Airport", " Migori Airport", " Moi International Airport", " Moyale Airport (Moyale Lower Airport)", " Mtito Andei Airport", " Jomo Kenyatta International Airport", " Wilson Airport", " Naivasha Airport", " Nakuru Airport",
    " Nanyuki Airport", " Narok Airport", " Nyeri Airport", " Samburu Airport", " Ukunda Airport", " Voi Airport", " Wajir Airport",
    "Chileka International Airport", " Chelinda Airport", "  Chitipa Airport", " Club Makokola Airport", " Dwanga Airport", " Karonga Airport", "  Kasungu Airport", " Lifupa Airport", " Likoma Airport", " Lilongwe International Airport (Kamuzu Int'l)", " Old Lilongwe Airport (Air Wing)", " Liwonde Makanga Aerodrome", " Mangochi Airport", " Mchinji Airport", " Monkey Bay Airport", " Mzuzu Airport", " Sucoma Airport", " Nsanje Airport", " Ntchisi Airport", " Salima Airport", " Zomba Airport",
    "Bobete Airport", " Katse Airport", " Kuebunyane Airport", " Lebakeng Airport", " Leribe Airport", " Lesobeng Airport", "  Kolberg Airport", " Letseng Airport", " Mafeteng Airport", " Malefiloane Airstrip", "Matekane Air Strip", " Mantsonyane Airport", " Mantsonyane Airport", " Matabeng Store Airstrip", " Matsaile Airport", " Mohale's Hoek Airport", " Mohlanapeng Airport", " Mokhotlong Airport", " Moshoeshoe I International Airport", " Mejametalana Airport", " Nkaus Airport", " Nohana Airport", " Pelaneng Airport", " Quthing Airport", " Qacha's Nek Airport", " Sehlabathebe Airport", " Sehonghong AirportSekake", " AirportSemenanyane Airport", " Semonkong Airport", " Seshutes Airport", " Tebellong Airport", " Thaba Tseka Airport", " Tlokoeng Airport"

  ])
  /////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });


  const multiDataSet = [
    {
      columns: [
        { title: "S.No", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true, color: "87CEEB", underline: true } } },//pixels width 
        { title: "Mr#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "Lab#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Patient Name", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Mobile", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Booking Date/Time", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Booking Ref PNR", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Destination", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Passport", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },

      ],
      data: data_export
    }
  ];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  const getData = async (data) => {


    var rows_export = []
    var d = date.toISOString()
    var date_ = new Date(d).ddmmyyy()

    var res = await data.map((item) => {
      var obj = [
        { value: item.srno, style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.patient_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.invoice_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.mobile, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.booking_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.reference_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.destination, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.passport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },

      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {


    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver"
    ).then((res) => res.json())
      .then((result) => {
        setBranchDetail(result)
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery={%22RegDateFrom%22:%22" + date.toISOString() + "%22,%22RegDateTo%22:%22" + date.toISOString() + "%22,%22vBranchID%22:%220%22}"
        ).then((res) => res.json())
          .then((result) => {
            var the_rows = []
            var srno = 1

            result.map((data) => {
              if (data.Flight_Date == "0000-00-00T00:00:00Z") {
                return
              }
              var res_date = new Date(data.Result_Date).ddmmyyy()
              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var test_res = data.Test_Result


              const Sample_Date = new Date(data.SampleDate);
              var Sample_Time = SampleToTime(data.SampleTime)
              Sample_Date.setHours(Sample_Time.split(":")[0], Sample_Time.split(":")[1])
              var flightDate = new Date(data.Flight_Date)
              var flight_time = FlightToTime(data.Flight_Time)
              flightDate.setHours(flight_time.split(":")[0], flight_time.split(":")[1])
              const milliseconds = Math.abs(flightDate - Sample_Date);
              const hours = Math.ceil(milliseconds / 36e5)

              if (data.Flight_Date == "0000-00-00T00:00:00Z") {
                flight_date = ""
              }
              if (data.Result_Date == "0000-00-00T00:00:00Z") {
                res_date = ""
              }
              if (data.Test_Result == "Covid19 NOT Detected") {
                test_res = 'negative'
              }
              if (data.Test_Result == "Covid19 Detected") {
                test_res = 'positive'
              }
              var booking_date = new Date(data.SampleDate).ddmmyyy()
              var booking_time = SampleToTime(data.SampleTime)
              if (test_res == "") {
                test_res = "Results not Ready Yet"
              }


              if (isNaN(hours)) {
                return
              }

              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var flight_time = FlightToTime(data.Flight_Time)

              var obj = {
                srno: srno,
                patient_id: data.SYSTEM_ID,
                invoice_id: data.Invoice_ID,
                hours: hours,
                name: data.NAME_F + " " + data.NAME_L,
                mobile: data.Mobile_No,
                UTF: hours >= 48 ? "More Than 48Hrs" : hours >= 24 ? "More Than 24Hrs" : "Less Than 24Hrs",
                booking_date_time: booking_date + " " + booking_time,
                result_date_time: res_date,
                reference_no: data.Ticket_no,
                flight_no: data.Flight_no,
                flight_date: flight_date,
                flight_time: flight_time,
                airline: data.Airline,
                destination: data.Destination,
                passport: data.Passport_No,
                result: test_res,
                download: <center>
                  <Button variant="contained" color="secondary" disableElevation onClick={() => {
                    if (data.Test_Status == 'Results Ready') {
                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                    } else {
                      alert('Results not Ready!!')
                    }
                  }} >
                    <  PictureAsPdfSharpIcon />
                  </Button>
                </center>
              }
              srno = srno + 1
              the_rows.push(obj)

            })

            setRows(the_rows)
            setOrg_Rows(the_rows)
            setLoading(false)
            getData(the_rows)
          })
      })
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <Grid container style={{ padding: '2em' }}>
        <Grid item xs={6} sm={6} md={3} lg={3}>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              inputVariant="outlined"
              label="To"
              format="dd/MM/yyyy"
              maxDate={new Date()}
              value={date}
              onChange={date => setDate(date)}
            />
          </MuiPickersUtilsProvider>

        </Grid>
        <GridItem xs={6} sm={6} md={3} lg={3}>
          <FormControl variant="filled" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="filled-age-native-simple">Hours Filter</InputLabel>
            <Select
              native
              value={hoursFilter}
              onChange={(e) => {
                sethoursFilter(e.target.value)
              }}
              inputProps={{
                name: 'status',
                id: 'filled-age-native-simple',
              }}
            >
              <option aria-label="Select" value="All" >All</option>
              <option value={'More Than 48Hrs'}>More Than 48Hrs</option>
              <option value={'More Than 24Hrs'}>More Than 24Hrs</option>
              <option value={'Less Than 24Hrs'}>Less Than 24Hrs</option>


            </Select>
          </FormControl>
        </GridItem>


        <Grid item xs={6} sm={6} md={3} lg={3} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" disableElevation
            onClick={() => {
              fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery={%22RegDateFrom%22:%22" + date.toISOString() + "%22,%22RegDateTo%22:%22" + date.toISOString() + "%22,%22vBranchID%22:%22" + branch + "%22}"
              ).then((res) => res.json())
                .then((result) => {
                  var the_rows = []
                  var srno = 1

                  result.map((data) => {
                    if (data.Flight_Date == "0000-00-00T00:00:00Z") {
                      return
                    }
                    const Sample_Date = new Date(data.SampleDate);
                    var Sample_Time = SampleToTime(data.SampleTime)
                    Sample_Date.setHours(Sample_Time.split(":")[0], Sample_Time.split(":")[1])
                    var flightDate = new Date(data.Flight_Date)
                    var flight_time = FlightToTime(data.Flight_Time)
                    flightDate.setHours(flight_time.split(":")[0], flight_time.split(":")[1])
                    const milliseconds = Math.abs(flightDate - Sample_Date);
                    const hours = Math.ceil(milliseconds / 36e5)

                    var res_date = new Date(data.Result_Date).ddmmyyy()
                    var flight_date = new Date(data.Flight_Date).ddmmyyy()
                    var test_res = data.Test_Result

                    if (data.Flight_Date == "0000-00-00T00:00:00Z") {
                      flight_date = ""
                    }
                    if (data.Result_Date == "0000-00-00T00:00:00Z") {
                      res_date = ""
                    }

                    if (data.Test_Result == "Covid19 NOT Detected") {
                      test_res = 'negative'
                    }
                    if (data.Test_Result == "Covid19 Detected") {
                      test_res = 'positive'
                    }
                    var booking_date = new Date(data.SampleDate).ddmmyyy()
                    var booking_time = SampleToTime(data.SampleTime)
                    if (test_res == "") {
                      test_res = "Results not Ready Yet"
                    }
                    if (hoursFilter != "All" && hoursFilter == "More Than 48Hrs") {
                      if (hours < 48) {
                        return
                      }
                    }
                    if (hoursFilter != "All" && hoursFilter == "More Than 24Hrs") {
                      if (hours < 24) {
                        return
                      }
                    }
                    if (hoursFilter != "All" && hoursFilter == "Less Than 24Hrs") {
                      if (hours >= 24) {
                        return
                      }
                    }


                    if (isNaN(hours)) {
                      return
                    }

                    var flight_time = FlightToTime(data.Flight_Time)


                    var obj = {
                      srno: srno,
                      patient_id: data.SYSTEM_ID,
                      invoice_id: data.Invoice_ID,
                      name: data.NAME_F + " " + data.NAME_L,
                      mobile: data.Mobile_No,
                      hours: hours,
                      booking_date_time: booking_date + " " + booking_time,
                      result_date_time: res_date,
                      reference_no: data.Ticket_no,
                      UTF: hours >= 48 ? "More Than 48Hrs" : hours >= 24 ? "More Than 24Hrs" : "Less Than 24Hrs",
                      flight_no: data.Flight_no,
                      flight_date: flight_date,
                      airline: data.Airline,
                      flight_time: flight_time,
                      destination: data.Destination,
                      passport: data.Passport_No,
                      result: test_res,
                      download: <center>
                        <Button variant="contained" color="secondary" disableElevation onClick={() => {
                          if (data.Test_Status == 'Results Ready') {
                            window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                          } else {
                            alert('Results not Ready!!')
                          }
                        }} >
                          <  PictureAsPdfSharpIcon />
                        </Button>
                      </center>
                    }
                    srno = srno + 1
                    the_rows.push(obj)

                  })
                  setPage(0)
                  setRows(the_rows)
                  setOrg_Rows(the_rows)
                  getData(the_rows)
                })
            }}

          >
            Seach<SearchIcon />
          </Button>

        </Grid>

        <Grid item md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <TextField id="record_search" label="Invoice ID, Passport No, Name , Mobile No ,Flight Info"
            style={{ maxWidth: '50em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
                console.log(item)

                if (item.passport.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return
                }


                if (item.mobile.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.airline.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.flight_no.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.name.toLowerCase().trim().includes(text.toLowerCase().trim())) {
                  lab_tests.push(item)
                  return

                }
                if (item.invoice_id.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }


                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />
        </Grid>
      </Grid>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                      {columns.map((column) => {
                        const value = row[column.id];
                        var status_color = value == "More Than 48Hrs" ? 'purple' : value == 'More Than 24Hrs' ? 'green' : value == 'Less Than 24Hrs' ? 'red' : ""
                        return (
                          <>
                            {status_color.trim() != '' ?
                              <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                <i><b>{value}</b></i>
                              </TableCell>
                              : <TableCell key={column.id} align={column.align} >
                                {value}
                              </TableCell>}
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      }
    </Paper>
  );
}
