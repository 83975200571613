import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 7,
        fontFamily:'Helvetica-Bold',
        fontSize:4,
        border:0.5,
        color:'black',
        textAlign: 'center',
        flexGrow: 1,

    },
    sn: {
        width: '10%',
        height:"100%",
        marginTop:"1em"
    },
    test_title: {
        width: '45%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"1em"
    },
    reporting_date: {
        width: '25%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"1em"
    },
    fee: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"1em"
    },
    disc: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"1em"
    },
    net_value: {
        width: '15%',
        borderLeftColor: borderColor,
        borderRightWidth: 0.5,
        borderLeftWidth: 0.5,
        height:"100%",
        marginTop:"1em"
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sn}>S/N</Text>
        <Text style={styles.test_title}>Test Title</Text>
        <Text style={styles.fee}>Fee</Text>
        <Text style={styles.disc}>Disc</Text>
        <Text style={styles.net_value}>Net</Text>
     
  
    </View>
  );
  
  export default InvoiceTableHeader