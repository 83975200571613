import React from 'react';
import {View, StyleSheet, Text ,Image, } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        // marginTop: 10,
    //    marginBottom:20,
    
        width:"380em",
        borderColor: 'black',
        // backgroundColor:"red"
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View >
   

   <View style={[
     styles.tableContainer]}>
   <InvoiceTableHeader  />
   <InvoiceTableRow items={invoice.tableData}  invoice={invoice} />
   <View style={ {
        flexDirection: 'row',
        marginTop:5,
        // alignItems: 'center',
        width:"100%",
        // height:30,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"green"
        }} key={"q123"}>
              <Text style={{ 

width: '65%',
textAlign: 'left',
borderColor: 'black',
borderWidth: 0.5,
// height:'100%',

fontSize:6.5,
padding: 5,

}}>
    {invoice.remarks}
</Text>
<Text style={{ 

width: '2%',
textAlign: 'left',
// height:'50%',

fontSize:6.5,
padding: 5,

}}>
  

</Text>
                  <Text style={{ 
                          
                 width: '18.5%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:20,
                //  alignSelf:"center",
                 
                 fontSize:6.5,
                 padding: 5,
// paddingBottom:10

                 
                 
                  }}>{"Total Recieved"}</Text>
                     <Text style={{ 
                          
                 width: '14%',
                 height:20,
                 textAlign: 'right',
                //  alignSelf:"center",

                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5,
// paddingBottom:10


                 
                 }}>{invoice.totalRecieved}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"2w1"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:5,
 flexDirection: 'column',



}}>
    
                            <Text style={{ fontSize: 7,}}>
                                User : {invoice.user}
                               

                            </Text>
                            <Text style={{ fontSize: 7, }}>
                            Date/Time : {invoice.currentDateTime} Hrs
                            

                            </Text>
                          
                          

</View>
                 

            </View>
  
   {/* <View style={{marginTop:invoice.reports.length>9&&invoice.reports.length<13?20:invoice.reports.length>13?40:invoice.invoice_id=="8401429"?10: 0}}>
        
        <View style={ {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"green"
        }} key={"q123"}>
              <Text style={{ 

width: '50%',
textAlign: 'left',
borderColor: 'black',
borderWidth: 0.5,
height:'100%',

fontSize:6.5,
padding: 5,

}}>
    {invoice.Invoice_Remarks}
</Text>
<Text style={{ 

width: '5%',
textAlign: 'left',
height:'100%',

fontSize:6.5,
padding: 5,

}}>
  

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Net Cost"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Value+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"2w1"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:5,
 flexDirection: 'column',



}}>
    
                            <Text style={{ fontSize: 7,}}>
                                User : {invoice.User_Name}
                            </Text>
                            <Text style={{ fontSize: 7, }}>
                            Date/Time : {invoice.invoice_date} {invoice.invoice_time} Hrs
                            </Text>
                          
                          

</View>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Discount"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Discount_Total+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
paddingTop:-10
}}>
  
                           

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Invoice Net Payable"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Payable+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>
    {
    invoice.flight_no!="" ?
                 
      <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
   fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
   {"I have read and verified my particulars as correct"}
   </Text>
  
               :
               null
    }

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Total Received Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Received+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>
      {
    invoice.flight_no!="" ?
                 
    <Text style={{ 
           
        textAlign: 'left',
        padding:2, 
        fontSize:6.5,
        color:'black'
        }}>
        {"Name : "+invoice.name}
        </Text>
  
               :
               null
    }

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Balance Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Balance+" Rs"}</Text>

            </View>
            </View>
           
            
            
*/}
</View> 
 
</View>
 
  
  );
  
  export default InvoiceItemsTable