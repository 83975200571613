import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Calibri Regular',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    part: {
        width: '33%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },
    part2: {
        width: '33%',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },

  });

  const LoginInfo = ({date}) => (
    <View style={styles.container}>
        <Text style={styles.part2}>WebLink</Text>
        <Text style={styles.part}>Android App</Text>
        <Text style={styles.part}>IOS App</Text>
  </View>
  );
  
  export default LoginInfo